<template>
  <div class="" ref="llm">

    <div v-html="msg"></div>
    <el-button type="primary" @click="llmStart">llmStart</el-button>
    <el-button type="primary" @click="llmStop">llmStop</el-button>
    <el-button type="primary" @click="log">log</el-button>
    <el-button type="primary" @click="message">message</el-button>
    <el-button type="primary" @click="sendTips">sendTips</el-button>

    <!-- dialog -->
    <el-button @click="handleOpenZhiling">打开弹窗</el-button>

        <el-dialog
          ref="myDialog"
          title="弹窗标题"
          :visible.sync="dialogVisible"
          width="40%"
          custom-class="custom-dialog"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          @close="handleClose"
        >
          <div class="dialog-content">
            <div class="main-content">
              <el-button type="primary" @click="$refs.myZhilingPanel.handleOpen();">
                打开右侧面板
              </el-button>

              <ZhilingOpen />
              <el-input type="textarea" ref="myTextarea" v-model="text"></el-input>
            </div>
            <el-select style="width: 6rem;" class="custom-select"
                  v-model="text"
                  filterable
                  size="mini"
                  placeholder="分组">
                <el-option
                  :label="'a'"
                  :key="'1'"
                  :value="'2'"
                />
            </el-select>
            <el-button @click="checkTextContains('XXX')">检查是否包含内容</el-button>
            <el-button @click="insertText('XXXXX')">插入文本</el-button>
          </div>
        </el-dialog>

        <!-- dialog-end -->


    <!-- <LlmSkeleton /> -->
    <ZhilingSelect v-show="showZhilingPanelFlag" ref="myZhilingPanel"/>


  </div>
</template>
<script>
import sse from '@/utils/llmstream'
import { create } from '@/api/statlog'
import LlmSkeleton from '@/components/LlmSkeleton'
import { EventBus } from '@/utils/eventbus';
import ZhilingSelect from '@/components/zhiling/zhiling-select';
import ZhilingOpen from '@/components/zhiling/zhiling-open';
import * as EnumApi from '@/api/enums'
import * as ImmessageApi from '@/api/immessage'
import { mapState, mapMutations} from 'vuex'
export default {
  components: {
    LlmSkeleton,ZhilingSelect,ZhilingOpen
  },
  data() {
    return {
      msg: '',
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      showModal: false,
      dialogVisible: false,
      text: "",
      cursorPosition: 0,
      enums: {},
    }
  },
  computed: {
  	...mapState(['showZhilingPanelFlag']),
  },
  mounted() {

    this.handleEnum()
  },
  methods: {
    ...mapMutations(['setEnums', 'setStoryContents',
     'setDialogShowCostomContactFlag', 'setShowZhilingPanelFlag']),
    handleConfirm(){
      alert('模态窗口确认');
    },
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.setEnums(result)
      })
    },
    handleOpenZhiling(){
      this.dialogVisible = true
    },
    handleClose() {

    },
    llmStart() {
      //1806516321777786881 标题
      //1784807018246393858 工作流002
      //1792808128543162370 知识图谱
      let storyText = `
Elon Musk 是 Tesla 和 SpaceX 的创始人。他在 2004 年加入 Tesla，并在 2008 年成为 CEO。
SpaceX 在 2002 年由 Musk 创立，目标是将人类送往火星。
`
      sse("1792808128543162370", {articleId: '1856219105523118081', storyOutlineNumber: 4, storyText: storyText}, this.handleMsg).then((result)=>{
        console.log('result', result);
      })
    },
    llmStop() {
      let processInstanceId = localStorage.getItem('processInstanceId')
      let queueName = localStorage.getItem('queueName')
      console.log('processInstanceId', processInstanceId);
      console.log('queueName', queueName);
      if((processInstanceId && processInstanceId.length > 0) && (queueName && queueName.length > 0)){
        ImmessageApi.llmStop({ processInstanceId, queueName }).then(res => {
          console.log('llmStop', res);
        })
      }
    },
    sendTips(){
      let tips = ''
      // 60s、提示用户：糟糕！脑电波链接失败！被神秘信号干扰，请尝试重新生成，或稍后重试！
      // 50S、提示用户：码哩码哩哄～(╯‵□′)╯︵┻━┻（持续念咒中···）
      // 40S、提示用户：码哩码哩哄～(╯°□°）╯︵ ┻━┻（念咒中···）
      // 30S、提示用户：共脑中(●--●)···
      // 20S、提示用户：码哩正在读取你的想法(●°u°●)​ 」···
      // 10S、提示用户：码哩正在打开脑电波，接受信号中O(∩_∩)O~···
      const duration = Math.floor(Math.random() * 6);
      console.log('duration',duration);
      if(duration == 5){
        tips = '糟糕！脑电波链接失败！被神秘信号干扰，请尝试重新生成，或稍后重试！'
      }else if(duration == 4){
        tips = '码哩码哩哄～(╯‵□′)╯︵┻━┻（持续念咒中）'
      }else if(duration == 3){
        tips = '码哩码哩哄～(╯°□°）╯︵ ┻━┻（念咒中）'
      }else if(duration == 2){
        tips = '提示用户：共脑中(●--●)'
      }else if(duration == 1){
        tips = '码哩正在读取你的想法(●°u°●)​ 」'
      }else if(duration == 0){
        tips = '码哩正在打开脑电波，接受信号中O(∩_∩)O~'
      }
      console.log('tips',tips);
      EventBus.$emit('llm-tips', tips);
    },
    handleMsg(msg){
      // console.log('msg', msg);
      this.msg = this.replaceLineBreaks(msg)
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    message(){
      this.$message({
        dangerouslyUseHTMLString: true,
        message: '抱歉，AI忙不过来啦，请您重新生成试试哦，如有其它问题可<a style="color: #0052D9;font-weight: bold;" href="https://work.weixin.qq.com/kfid/kfc73d694476dbadaf1" target="_blank">联系客服</a>',
        type: 'error',
        duration: 8 * 1000
      })
    },
    log(){
      create({predicate: 'BAIDU_TUIGUANG', objectText: 'querystring'});
    },
  }
};
</script>
<style>
.dialog-content {
  /* display: flex; */
  height: 60vh; /* 控制弹窗内容高度 */
  /* position: relative; */
}

.el-select-dropdown {
  z-index: 9001 !important;
}
.main-content {
  flex: 1;
  padding: 20px;
}

.right-panel {
  margin-top: -3rem;
  margin-right: -20vw;
  width: 20vw;
  background: #fff;
  border-left: 1px solid #ebeef5;
  height: calc(100% + 4rem);
  display: flex;
  flex-direction: column;
}

.panel-header {
  padding: 15px;
  border-bottom: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-btn {
  font-size: 24px;
  padding: 0;
}

.panel-content {
  flex: 1;
  padding: 20px;
  overflow: auto;
}

/* 调整弹窗内边距 */
:deep(.custom-dialog) .el-dialog__body {
  padding: 0;
}
</style>
<style scoped>
.container1 {
  display: flex;
  flex-wrap: wrap; /* 允许换行 */
  gap: 10px; /* 元素间距 */
}

.item1 {
  flex: 1 1 auto; /* 关键属性：自适应伸缩，基础宽度30% */
  min-width: 200px; /* 可选：避免元素过小 */
  box-sizing: border-box; /* 确保边框和内边距不影响宽度计算 */
}
.mly-shadow {
  padding: 25px 32px;
  box-shadow: 2px 2px 10px 0px rgba(54,110,244,0.07);
}
.mly-shadow:hover{
  cursor: pointer;
  box-shadow: 4px 4px 15px 0 rgba(54, 110, 244, .2);
}
/deep/ video {
  mix-blend-mode: screen;
  /* background: #000000; */
  background: transparent;
}
.container {
    width: 100%; /* 或者你想要设置的宽度 */
    height: 400px; /* 或者你想要设置的高度 */
    overflow: hidden;
    position: relative;
    background-image: url('../assets/newer-gift-v2.png');/* 替换为你的图片地址 */
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom center;
}

.stretch {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%; /* 或者你想要拉伸的部分的高度 */
  background-image: url('../assets/newer-gift-v2.png');
  background-repeat: repeat-x;
  background-size: 100% auto;
  background-position: bottom center;
}
.frame-player {
    position: absolute;
    width: 768px;
    height: auto;
}
.filter-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  filter: blur(10px);
  color: #FFFFFF;
  z-index: -1;
}
/* 容器样式 */
.table-container1 {
  display: grid;
  grid-template-rows: repeat(2, 1fr); /* 2行，每行高度相等 */
  grid-auto-flow: column; /* 允许项目填充更小的空格 */
  /* grid-auto-columns: repeat(1, 1fr); */
  gap: 8px; /* 单元格间隙 */
  /* width: auto; */
  overflow-y: hidden;
  overflow-x: auto;
  background: pink;
}

/* 单元格样式 */
.table-cell1 {
  display: flex;
  border: 1px solid #000; /* 边框样式 */
  padding: 8px; /* 单元格内边距 */
  box-sizing: border-box; /* 边框和内边距包含在宽度内 */
  width: 10rem;
}

/* 内容样式，用于调整内容以适应单元格宽度 */
.content {
  width: 100px;
  height: 100px;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
}


/* png iframe */
.animation-container {
  position: relative;
  width: 100%; /* 根据你的帧尺寸调整 */
  height: 100%; /* 根据你的帧尺寸调整 */
}

.animation-frame {
  width: 100%;
  height: auto;
  display: block;
}

/* 定义关键帧动画 */
@keyframes play-sequence {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* 应用动画到图像 */
.animation-frame {
  animation: play-sequence 30ms steps(1) infinite;
}
/* png iframe end */
</style>
