export default {
  install: Vue => {
    Vue.prototype.$filterHtml = function (text) {
        text = text?text:''
        text = text.replace(/(?=\S)<br\s*\/?>/gi, '');//br
        text = text.replace(/<p>(.*?)<\/p>/g, (match, content) => {
          return `${content}\n`; // 保留内容并在其后追加换行符
        });
        text = text.replace(/<[^>]*>?/gm, '');// 去除 <>
        // console.log('remove html tag br', noHtmlString)
        return text;
    };

    const errorMsg = '抱歉，章纲格式有误，章节序号必须为纯数字且单独一行，例如01，并且每个章节序号的数字要连续递增01>02>03'

    Vue.prototype.$validateTitle = function (content) {
        content = this.$filterHtml(content)
        const lines = content.split('\n');
        let prevChapterNumber = -1; // 用于记录上一个章节序号
        let hasContent = false; // 用于检查是否有章节内容

        for (let i = 0; i < lines.length; i++) {
            let line = lines[i].trim(); // 清除行首和行尾的半角空格
            line = line.replace(/^[\u3000\u0020]+|[\u3000\u0020]+$/g, ''); // 清除全角和半角空格
            console.log(`line： ${line}`);
            // 检查是否是章节序号行
            if (/^\d+$/.test(line)) {
                const currentChapterNumber = parseInt(line, 10);
                // 检查章节序号是否递增
                if (currentChapterNumber <= prevChapterNumber) {
                  // this.$message.error(`错误：章节序号 ${currentChapterNumber} 不是递增的。`);
                  this.$message.error(errorMsg);
                  return false;
                }
                // 检查下一行是否有内容（章节细纲）
                if (i + 1 >= lines.length || lines[i + 1].trim() === '') {
                    // this.$message.error(`错误：章节 ${currentChapterNumber} 没有内容。`);
                    this.$message.error(errorMsg);
                    return false;
                }
                prevChapterNumber = currentChapterNumber;
                hasContent = true;
            }
        }
        // 检查是否有至少一个章节
        if (!hasContent) {
          // this.$message.error(`错误：章纲必须包含至少一个章节。`);
          this.$message.error(errorMsg);
          return false;
        }
        console.log(`通过：校验成功！`);
        return true;
    };

    Vue.prototype.$parseContent = function (text) {
       const storyOutlinesPrue = []
       if(!this.$validateTitle(text)){
         return storyOutlinesPrue;
       }
       text = this.$filterHtml(text)
       text = text + '\n 9527'
       const regex = /^(?:\s*(\d+)\s*(?:\n(?!\s*\d+\s*$).*)*)(?=(\n\s*\d+\s*$)|\Z)/gm;
       let matches;
       const outlineNumber = [];

       // 使用循环来处理所有匹配的项
       while ((matches = regex.exec(text)) !== null) {
         const number = matches[1];
         // matches[0] 是整个匹配的字符串，包括数字和文本
         const groupWithNumber = matches[0];
         // 提取文本内容，去除数字行
         // const texts = groupWithNumber.split('\n').slice(1); // 从第二行开始，因为第一行是数字
         const texts = groupWithNumber.replace(number, '').trim(); // 从第二行开始，因为第一行是数字
          outlineNumber.push(parseInt(number, 10))
          // 将数字和文本内容添加到结果数组
          storyOutlinesPrue.push(texts);
       }
       console.log(`通过:`,storyOutlinesPrue);
       return storyOutlinesPrue
    };

    Vue.prototype.$treeForeach = function (tree, func) {
      tree.forEach(data => {
        func(data)
        data.children && this.$treeForeach(data.children, func) // 遍历子树
      })
    };

    Vue.prototype.$buildTree = function (nodes, parentId, depth){
      let tree = []
      for (var i = 0, length = nodes.length; i < length; i++) {
        let node = nodes[i];
        node.label = node.desc
        node.value = node.id
        let pid = node.parentId;
        if(parentId == pid){
          node.depth = depth
          let tempTree = this.$buildTree(nodes, node.id, depth+1);
          if(!tempTree || tempTree.length == 0) {
            node.children = null;
          } else{
            node.children = tempTree;
          }

          tree.push(node)
        }
      }
      return tree
    };

    Vue.prototype.$treeToList = function (nodes){
      let tree = []
      let depthInit = nodes[0].depth
      this.$treeForeach(nodes, node => {
        node.depth = node.depth - depthInit
        tree.push(node)
      })
      return tree
    };
    // 处理作品条件
    Vue.prototype.$handleChangeArticleTypeId = function (value, story) {
      story.articleTypeId = value[value.length - 1];
      console.log('$handleChangeArticleTypeId', value, story.articleTypeId);
    };
    Vue.prototype.$handleChangeArticleStyleId = function (value, story) {
      story.articleStyleId = value[value.length - 1];
      console.log('$handleChangeArticleStyleId', value, story.articleStyleId);
    };
    Vue.prototype.$handleChangeArticleAgeId = function (value, story) {
      story.articleAgeId = value[value.length - 1];
      console.log('$handleChangeArticleAgeId', value, story.articleAgeId);
    };
  }
}
