<template>

  <div class="mly-layout" style="">

    <HeadNavWriter @get-hide-flag="getHideFlag" style=""/>
<!--    <div class="" style="height: 3rem;" v-if="showMaskFlag">

    </div> -->

    <!-- view window -->
    <div class="mly-main">

      <!-- left menu -->
      <div class="writer-bg hidden-xs-only mly-aside">
          <el-menu
            :default-openeds="defaultOpeneds"
            style="border: 0;color: #fff;"
            active-color="#0252D9" background-color="#242424"
            :default-active="activeMenuItem"
            class="el-menu-agent"
            @select="handleSelect"
            @open="handleOpen"
            @close="handleClose">
            <el-menu-item index="1" :class="{'is-active': activeMenuItem == '1'}">
              <img style="width: 1rem;margin-right: 10px;" src="@/assets/menu-edit.png"/>
              <span slot="title" style="color: #fff;">创作空间</span>
            </el-menu-item>
            <el-menu-item index="2" :disabled='false' :class="{'is-active': activeMenuItem == '2'}">
              <img style="width: 1rem;margin-right: 10px;" src="@/assets/menu-chaishu.png"/>
              <span slot="title" style="color: #fff;">拆文学习</span>
            </el-menu-item>
            <!-- 带有子菜单的菜单项 -->
            <el-submenu index="3" :class="{'is-active3': true}">
              <template slot="title">
                <img style="width: 1rem;margin-right: 10px;" src="@/assets/menu-shuomingshu.png"/>
                <span slot="title" style="color: #fff;">创作指南</span>
              </template>
              <!-- 子菜单项 -->
              <el-menu-item :class="{'is-active3': true}" index="3-1">
                <span slot="title" style="color: #fff;">入门必看</span>
              </el-menu-item>
              <el-menu-item :class="{'is-active3': true}" index="3-2"><span slot="title" style="color: #fff;">爆款窍门</span></el-menu-item>
              <el-menu-item :class="{'is-active3': true}" index="3-3"><span slot="title" style="color: #fff;">实操视频</span></el-menu-item>
            </el-submenu>
            <el-menu-item index="4" v-if='false'>
              <img style="width: 1rem;margin-right: 10px;" src="@/assets/menu-lib.png"/>
              <span slot="title" style="color: #fff;">素材宝库(敬请期待)</span>
            </el-menu-item>
            <el-menu-item index="5" v-if='false'>
              <img style="width: 1rem;margin-right: 1rem;" src="@/assets/menu-tips.png"/>
              <span slot="title" style="color: #fff;">灵感源泉(敬请期待)</span>
            </el-menu-item>
          </el-menu>
      </div>
      <!-- right view -->
      <div style="z-index: 1;" class="mly-container global-box-scrollbar">
        <IndexPage v-if="token == '' && activeMenuItem == '1'" />
        <router-view v-if="token == '' && activeMenuItem == '2'" />
        <router-view v-if="token != ''" />
      </div>
    </div>

    <!-- footer -->
    <MainFooter style="margin-top: 50px;" v-if="!isMobile() && token == ''" />
  </div>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import MainFooter from '@/components/MainFooter'
import IndexPage from '@/components/IndexPage'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'Article',
  components: {
    HeadNavWriter,MainFooter,IndexPage
  },
  data() {
    return {
      sliderBoxActive: 0,
      defaultOpeneds: ['3'],
      showMaskFlag: true
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState(['activeMenuItem', 'token', 'showMenuFlag']),
    currentPath() {
      return this.$route.path;
    }
  },
  beforeDestroy() {

  },
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    getHideFlag(flag){
      console.log('getHideFlag', flag);
      this.showMaskFlag = flag
    },
    isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    init(){
      console.log('init');
      if(this.currentPath == '/bookbreakdown/index'){
        this.setActiveMenuItem('2')
      }else if(this.currentPath == '/home' || this.currentPath == '/'){
        this.setActiveMenuItem('1')
      }
      if(this.currentPath == '/'){
        if(this.token != ''){
          this.$router.push('/home')
        }
      }else{
        this.$router.push(this.currentPath)
      }
    },
    handleSelect(key, keyPath) {
      // this.setActiveMenuItem(key+'')
      console.log('new index', key, keyPath, this.activeMenuItem);
      if(key == 1){
        this.setActiveMenuItem(key+'')
        if(this.token == ''){
          this.$router.push('/')
        }else{
          this.$router.push('/home')
        }
      }else if(key == 2){
        this.setActiveMenuItem(key+'')
        this.$router.push('/bookbreakdown/index')
      }else if(key == '3-1'){
        let url = 'https://qcnx120ai68z.feishu.cn/wiki/CuTuwXTpaifTflkpWiQcCZIlnSe?from=from_copylink'
        window.open(url, '_blank')
      }else if(key == '3-2'){
        let url = 'https://qcnx120ai68z.feishu.cn/wiki/X6GswLv6xiNxm9kb3KxczTRdnDc?from=from_copylink'
        window.open(url, '_blank')
      }else if(key == '3-3'){
        let url = 'https://qcnx120ai68z.feishu.cn/wiki/C8nnwi9NPi77ujkeW2RcnRglnxf?from=from_copylink'
        window.open(url, '_blank')
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  }
}
</script>

<style scoped lang="scss">
.mly-layout {
  height: 100%;
  background: #242424;
  display: flex;
  flex-direction: column;
}
.mly-main {
  min-height: 1px;
  flex: 1;
  height: 100%;
  overflow: hidden; /* 禁止父容器滚动 */
  display: flex;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.mly-aside {
  height: 100%;
  margin-top: 1rem;
}
.mly-container {
  background: #FAFAFD;
  border-radius: 20px;
  height: calc( 100% - 1rem );
  margin: 0 1rem 1rem;
  // margin-bottom: 1rem;
  padding:1rem;
  overflow: auto;
  flex: 1;
}
/* 假设你的菜单类名为 .my-menu */
/deep/ .el-menu-agent .el-menu-item {
  border-radius: 10px !important;
  margin: 0 10px 6px;
  padding: 0 20px !important;
  height: 2.5rem;
  line-height: 2.5rem;
}
/deep/ .el-menu-agent .el-submenu .el-menu-item {
  min-width: 0;
  text-align: center;
}
/deep/ .el-submenu__title {
  display: flex;
  align-items: center;
  padding-left: 30px !important;
  padding-right: 10px;
}
/deep/ .el-submenu__icon-arrow {
    position: relative;
    top: 0;
    right: -2px;
    margin: 0;
}
/deep/ .el-menu-agent .el-submenu{
  border-radius: 10px !important;
  padding: 0;
}
/deep/ .el-menu-agent .el-menu-item:hover {
  background-color: #4B4B4B !important;
}
/deep/ .el-menu-agent .el-submenu .el-menu-item:hover {
  background-color: #4B4B4B !important;
}
.el-menu-agent .el-menu-item.is-active {
  background-color: #0252D9 !important; /* 激活状态下的背景颜色 */
}
.el-menu-agent .el-menu-item.is-active3 {
  background-color: #242424 !important; /* 激活状态下的背景颜色 */
}
</style>
