<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter />

    <div class="writer-box-content">

      <!-- steps -->
      <StepsWriter :active="5"/>

      <!-- elements -->
      <div class="preview-box" style="height: calc(100% - 7rem);">
        <div class="" style="font-weight: 600; font-size: 20px; color: #000000; line-height: 24px;margin-bottom: 1.5rem;">
          {{story.title}}
        </div>
        <div class="writer-box-content-editor global-box-scrollbar" v-html="replaceLineBreaks(texts.join('\n\n'))">

        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="writer-box-footer">
      <span class="button-group">
        <el-button class="custom-button" :size="$root.buttonSize" type="default" @click="goBack">返回修改</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" icon="el-icon-download" @click="downloadFile2">下载全文</el-button>
      </span>
    </div>

  </div>
</template>
<script>
import StepsWriter from '@/components/StepsWriterZhihu'
import HeadNavWriter from '@/components/HeadNavWriter'
import LyRichEditor from '@/components/RichEditor'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as StoryContentApi from '@/api/storycontent'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    LyRichEditor, HeadNavWriter, StepsWriter
  },
  data() {
    return {
      enums: [],
      texts: [],
    	targetPermit: 'article',
    }
  },
  computed: {
  	...mapState(['user', 'story', 'storyContents'])
  },
  mounted() {
    this.handlePageStoryContent()
  },
  methods: {
    ...mapMutations(['setStory']),
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    handleRemove(item) {
      let that = this;
      that.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ArticleApi.remove(item.id).then(res => {

        });
      }).catch((e) => {console.log('error', e)});
    },
    handlePublish(item) {
      let that = this;
      let tip = !item.publishFlag?'发布':'取消发布'
      that.$confirm('', '确定'+tip+'吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ArticleApi.published({id: item.id, enabled: !item.publishFlag}).then(res => {

        });
      }).catch((e) => {console.log('error', e)});
    },
    downloadFile() {
      const filename = this.story.title
      const text = this.story.content
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename + '.txt');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    downloadFile2() {
      const filename = this.story.title
      const text = this.story.content
      var FileSaver = require('file-saver');
      var blob = new Blob([text], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, filename);
    },
    goBack() {
      this.$router.push('/workspace/gen4');
    },
    handlePageStoryContent(){
      if(this.storyContents.length > 0) {
        this.storyContents.forEach((item, index) => {
          this.texts.push(item.content)
        })
      }else{
        StoryContentApi.page({storyId: this.story.id, size: 100}).then(res => {
          let result = res.data.data;
          if(res.data.code == 0){
            if(result.records.length > 0){
              // console.log('story contents', result.records);
              result.records.forEach((item, index) => {
                this.texts.push(item.content)
              })
              // console.log('texts', this.texts);
            }
          }
        })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.writer-box {
  &-content {
    &-editor {
      height: calc(100% - 6.5rem);
      overflow: auto;
      line-height: 1.5rem;
      font-weight: 400;
      font-size: 1rem;
      color: #000000;
    }
  }
}
.preview-box {
  background: #ffffff;
  padding: 2.5rem;
  margin-top: 2rem;
  border-radius: 20px;
  box-shadow: 2px 4px 7px 0px rgba(205,211,223,0.35);
}
@media screen and (max-width: 768px) {
  .preview-box {
    margin-top: 1rem;
    padding: 1rem;
    height: calc( 100% - 7rem );
  }
}
</style>
