<template>
<div>
    <!-- 三个div块元素，在大屏下排成一行，小屏下，每个元素一行 -->
    <div class="mly-container" style="margin-bottom: 1.5rem;">
      <!-- 创建作品 -->
      <div class="mly-item">
        <div class="mly-shadow mly-border-radius-30"
          style="background: linear-gradient( 185deg, #DEEBFF 0%, #AFCCFE 100%);">
          <div class="mly-detail">
            <div class="d-flex" style="align-items: center;">
              <div class="mly-title">
                创建作品
              </div>
              <div class="mly-shangsheng d-flex" style="margin-left: 10px;">
                <img style="height: 12px;" src="@/assets/icon-shangsheng.png" />
                <div>80%</div>
              </div>
            </div>
            <div class="mly-detail-text-cj" style="color: rgba(0,0,0,0.4);">
              快速高效专业，20分钟一篇高质量小说
            </div>

            <!-- 1 -->
            <div class="d-flex" style="gap: 8px;margin-top: 8px;">
              <div class="duanpian cj-box" style="" @click="handleGen('genbyzhihu')">
                <div class="left">
                  <div class="cj-title">
                    短篇小说
                  </div>
                  <div class="cj-zishu">
                    8000-15000字
                  </div>
                </div>
                <div class="tubiao" style="">
                  <img style="" src="@/assets/icon-dp.png" />
                </div>
              </div>
              <div class="changpian cj-box" style="" @click="handleGen('genbychangpian')">
                <div class="left">
                  <div class="cj-title">
                    长篇小说
                  </div>
                  <div class="cj-zishu">
                    每章约2000字
                  </div>
                </div>
                <div class="tubiao" style="">
                  <img style="" src="@/assets/icon-cp.png" />
                </div>
              </div>
            </div>
            <!-- 2 -->
            <div class="d-flex" style="gap: 8px;margin-top: 8px;">
              <div class="chaoduanpian cj-box" style="" @click="handleGen('genbyidea')">
                <div class="left">
                  <div class="cj-title">
                    超短篇小说
                  </div>
                  <div class="cj-zishu">
                    3000-6000字
                  </div>
                </div>
                <div class="tubiao" style="">
                  <img style="" src="@/assets/icon-cdp.png" />
                </div>
              </div>
              <div class="dingzhi cj-box" style="" @click="handleGen('genbyoutline')">
                <div class="left">
                  <div class="cj-title">
                    短篇定制
                  </div>
                  <div class="cj-zishu">
                    8000-3W字
                  </div>
                </div>
                <div class="tubiao" style="">
                  <img style="" src="@/assets/icon-dz.png" />
                </div>
              </div>
            </div>
            <!-- 3 -->
            <div class="chaishu cj-box" style="margin-top: 8px;
              background: linear-gradient( 184deg, #88B4FC 0%, #79A1FF 100%);box-shadow: inset 2px 4px 6px 0px rgba(255,255,255,0.12);" @click="handleGen('chaishu')">
              <div class="left">
                <div class="cj-title-chaishu">
                  拆书学习
                </div>
                <div class="cj-zishu-chaishu">
                  快速跟风必备神器
                </div>
              </div>
              <div class="tubiao-chaishu" style="">
                <img style="" src="@/assets/icon-cs.png" />
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- 案例 -->
      <div class="mly-item">

        <div class="d-flex" style="flex-direction: column;">

          <!-- 真实案例 -->
          <div class="mly-shadow mly-border-radius-30 d-flex" style="background: #FFFFFF;gap: 16px;position: relative;margin-bottom: 14px;">
            <div class="left">
              <div class="">
                <div class="mly-title">
                  真实案例
                </div>
                <div class="mly-subtitle mly-border-radius-20"
                  style="background: linear-gradient( 88deg, #366EF4 0%, #749DFF 100%);margin: 8px 0;">
                  写作变现实录
                </div>
                <div class="mly-detail d-flex">
                  <div class="d-flex" style="align-items: center;">
                    <img style="width: 5rem;" src="@/assets/icon-jiqiao.png" />
                  </div>
                </div>
              </div>
            </div>
            <div class="right"
              style="height: 76px;
                  overflow: hidden;
                  margin-top: 0.5rem;">
              <div class="mly-detail" :class="{'animate-up': animateUp}">
                <div
                  @click="$router.push('/newsdetail/'+item.id)"
                  style="color: rgba(70,124,255,0.8);line-height: 24px;"
                 class="mly-detail-text zhenshi-active pointer"
                 v-for="(item, index) in anlis" :key="item.id">
                 {{'·' + item.title}}
                </div>
              </div>
            </div>
            <div class="mly-detail"
                @click="$router.push('/newslist/EXAMPLE')"
                style="position: absolute;top: 1rem;right: 2rem;">
              <div class="d-flex pointer" style="align-items: center;">
                <span style="font-size: 10px;color: #366EF4;">更多</span><i style="font-size: 10px;color: #366EF4;" class="el-icon-d-arrow-right"></i>
              </div>
            </div>
          </div>

          <!-- 经验技巧 -->
          <div class="mly-shadow mly-border-radius-30 d-flex" style="background: linear-gradient( 197deg, #B7C0FF 0%, #647EFF 100%);gap: 16px;position: relative;">
            <div class="left">
              <div
                style="">
                <div class="mly-title" style="color: #FFFFFF;">
                  经验技巧
                </div>
                <div class="mly-subtitle mly-border-radius-20" style="background: linear-gradient( 90deg, #8488FA 0%, #ACB2FF 100%);margin: 8px 0;">
                  创作变现秘籍
                </div>
                <div class="mly-detail d-flex">
                  <div class="d-flex" style="align-items: center;">
                    <img style="width: 5rem;" src="@/assets/icon-anli.png" />
                  </div>
                </div>
              </div>
            </div>
            <div class="right"
              style="height: 76px;
                      overflow: hidden;
                      margin-top: 0.5rem;">
              <div class="mly-detail" :class="{'animate-up': animateUp}">
                <div
                  @click="$router.push('/newsdetail/'+item.id)"
                  style="color: rgba(255,255,255,0.8);line-height: 24px;"
                  class="mly-detail-text jiqiao-active pointer"
                 v-for="(item, index) in jiqiaos" :key="item.id"> {{'·' + item.title}} </div>
              </div>
            </div>
              <div class="mly-detail"
                @click="$router.push('/newslist/SKILL')"
                style="position: absolute;top: 1rem;right: 2rem;">
                <div class="d-flex pointer" style="align-items: center;">
                  <span style="font-size: 10px;color: rgba(255,255,255,0.8);">更多</span><i style="font-size: 10px;color: rgba(255,255,255,0.8);" class="el-icon-d-arrow-right"></i>
                </div>
              </div>
          </div>

        </div>
      </div>

      <!-- 推荐-模板 -->
      <div class="mly-item d-flex" style="gap: 16px;">

        <!-- tuijian -->
        <div class="mly-shadow mly-border-radius-30"
          style="background: #FFFFFF;position: relative;">
          <div class="mly-title">
            AI创作精选
          </div>
          <div class="mly-subtitle mly-border-radius-20" style="background: linear-gradient( 88deg, #366EF4 0%, #749DFF 100%);">
            AI灵感，精品呈现
          </div>
          <div class="mly-detail" style="">
            <div
              @click="$router.push('/newsdetail/'+item.id)"
              style="color: #366EF4;line-height: 30px;"
              class="mly-detail-text-moban mly-detail-text-tuijian pointer"
              v-for="(item, index) in tuijians" :key="item.id"> {{'·' + item.title}} </div>
          </div>
          <div class="d-flex flex-y-center pointer"
            @click="$router.push('/newslist/PREMIUM')"
            style="position: absolute;bottom: 1rem;right: 2rem;">
            <span style="font-size: 10px;color: #366EF4;">更多</span><i style="font-size: 10px;color: #366EF4;" class="el-icon-d-arrow-right"></i>
          </div>
        </div>

        <!-- moban -->
        <div class="mly-shadow mly-border-radius-30 d-flex-1 duanpian-moban"
          style="background: linear-gradient( 185deg, #E0E0FF 0%, #B5BEFF 100%);position: relative;">

          <div class="mly-detail">
            <div class="d-flex" style="align-items: center;">
              <div class="mly-title">
                短篇爆款模版
              </div>
            </div>
            <div class="d-flex" style="align-items: center;margin-top: 6px;margin-bottom: 8px;">
              <div class="mly-shangsheng d-flex">
                <img style="height: 12px;" src="@/assets/icon-shangsheng.png" />
                <div>80%</div>
              </div>
            </div>
            <div class="mly-detail-text-moban" style="color: rgba(0,0,0,0.4);">
              超多爆款短篇故事模版，生成你的爆款短篇
            </div>
          </div>
          <div class="mly-detail" style="position: relative;padding-top: 1rem;">
            <div @click="handleTemplateClick(item)"
              style="width: 100%;"
             class="mly-border-radius-20 moban-title mly-detail-text-moban pointer"
             v-for="(item, index) in storyTemplates" :key="item.id">
              {{item.title}}
             </div>
             <div style="position: absolute;top: 2px;right: 2px;">
               <img class="duanpian-moban-hot" style="height: 27px;" src="@/assets/icon-moban-hot.png" />
             </div>
          </div>
          <div style="height: 1rem;"></div>
          <div class="d-flex flex-y-center pointer" v-if="false"
            @click="handleTemplateMore()"
            style="position: absolute;bottom: 1rem;right: 2rem;">
            <span style="font-size: 10px;color: rgba(0,0,0,0.6);">更多</span><i style="font-size: 10px;color: rgba(0,0,0,0.6);" class="el-icon-d-arrow-right"></i>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
import * as StoryTemplateApi from '@/api/storytemplate'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'BoxCreate',
  components: {

  },
  data() {
    return {
      timer: null,
      animateUp: false,
      dialogShowGenbyoutline: false,
      showStateFlag: true,
      dialogShowGenbyidea: false,
      activeMenuItem: 1,
      tuijians: [],
      jiqiaos: [],
      anlis: [],
      storyTemplates:[]
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    this.handleEnum()
    this.handleTemplatePage()
    this.handleTuijian()
    this.handleJiqiao()
    this.handleAnli()
    this.timer = setInterval(this.scrollAnimate, 5000)
  },
  computed: {
  	...mapState(['user', 'story', 'showDialogCdpFlag','isAuthenticated',
     'showDialogDpFlag', 'showGenbyoutlineFlag', 'storyTiaojian']),
  },
  methods: {
    ...mapMutations(['setStory', 'setActiveMenuItem', 'setStoryContents',
    'setShowGenbyoutlineFlag', 'setShowDialogCdpFlag', 'setShowDialogDpFlag',
    'setShowChangpianDialogFlag', 'setShowLoginDialogFlag','setActiveTemplate', 'setStoryTiaojian']),
    scrollAnimate() {
      this.animateUp = true
      setTimeout(() => {
        //anli
        this.anlis.push(this.anlis[0])
        this.anlis.shift()
        //jiqiao
        this.jiqiaos.push(this.jiqiaos[0])
        this.jiqiaos.shift()

        this.animateUp = false
      }, 4000)
    },handleTemplateClick(item) {
      if(this.isAuthenticated){
        this.$parent.handleTemplateClick(item)
      }else{
        this.setShowLoginDialogFlag(true)
      }
    },
    handleTemplateMore() {
      if(this.isAuthenticated){
        this.handleGen('genbyzhihu')
      }else{
        this.setShowLoginDialogFlag(true)
      }
    },
    handleTemplatePage(){
      StoryTemplateApi.page({
        templateType: 'GUANFANG',
        enableFlag: true,
        size: 4
      }).then(res => {
        let result = res.data.data;
        this.storyTemplates = result.records;
      });
    },
    handleGen(cmditem) {
      if(this.isAuthenticated){
        this.handleGenLogined(cmditem)
      }else{
        this.handleGenLogout(cmditem)
      }
    },
    handleGenLogout(cmditem) {
      if(cmditem == 'chaishu'){
        this.setActiveMenuItem('2')
        this.$router.push('/bookbreakdown/index')
      }else{
        this.setShowLoginDialogFlag(true)
      }
    },
    handleGenLogined(cmditem) {
      if (!cmditem) {
        this.$message.error('菜单选项缺少command属性')
        return
      }
      let story = {id: ''}
      switch (cmditem) {
        case 'genbyidea'://cdp
          // gen story
          story.articleGenType = 'GEN_BY_IDEA'
          story.articleType = 'TANBI_DOUBLE_BOY'
          this.setStory(story)
          this.setShowDialogCdpFlag(true)
          break
        case 'genbyoutline'://duanpian dingzhi
          // gen story
          story.articleGenType = 'GEN_BY_OUTLINE'
          story.articleType = 'FEEL_GOOD'
          story.articleStyle = 'ZHENGWEN'
          story.writingPerspective = 'FIRST_PERSON'
          this.setStory(story)
          this.setShowGenbyoutlineFlag(true)
          break
        case 'genbyzhihu'://dp
          story.articleGenType = 'GEN_BY_ZHIHU'
          story.articleType = 'FEEL_GOOD'
          this.setStory(story)
          this.$router.push({ path: '/workspace/gen'})
          this.$nextTick(()=>{
            setTimeout(()=>{this.setShowDialogDpFlag(true);}, 300)

          })
          break
        case 'genbychangpian'://changpian
          story.articleGenType = 'GEN_BY_CHANGPIAN'
          story.articleType = 'CP_YANQING'
          this.setStory(story)
          this.setShowChangpianDialogFlag(true)
          this.$router.push({ path: '/workspace/gencp'})
          break
        case 'chaishu':// chaishu
          this.$router.push({ path: 'bookbreakdown/index'})
          break
      }
    },
    handleEnum() {
      EnumApi.map().then(res => {
        let result = res.data.data;
        console.log('result', result);
        let tree = this.$buildTree(result, 0, 0)
        console.log('tree', tree);
        this.setStoryTiaojian = tree
      })
    },
    handleTuijian(){
      ArticleApi.page({size: 5, articleGroup: 'PREMIUM', editorFlag: true}).then(res => {
        let result = res.data.data;
        this.tuijians = result.records
      })
    },
    handleJiqiao(){
      ArticleApi.page({size: 5, articleGroup: 'SKILL', editorFlag: true}).then(res => {
        let result = res.data.data;
        this.jiqiaos = result.records
      })
    },
    handleAnli(){
      ArticleApi.page({size: 5, articleGroup: 'EXAMPLE', editorFlag: true}).then(res => {
        let result = res.data.data;
        this.anlis = result.records
      })
    }
  }
}
</script>

<style type="text/css">
.animate-up {
  transition: all 5s ease-in-out;
  transform: translateY(-25px);
}
</style>
<style scoped lang="scss">

// mly
.mly-container {
  display: flex; /* 使用Flexbox布局 */
  flex-wrap: wrap; /* 允许元素换行 */
  gap: 16px; /* 设置元素之间的间距 */
}
.mly-item {
  // flex: 1; /* 子元素平分空间 */
  flex: 1 1 378px; /* 关键属性：自适应伸缩，基础宽度30% calc( (100% - 48px) / 3 ) */
  //min-width: ; /* 可选：避免元素过小 */
  max-width: 378px;
  box-sizing: border-box; /* 确保边框和内边距不影响宽度计算 */
}
.zhenshi-active:hover {
  font-weight: 600;
  color: #366EF4 !important;
  padding-left: 3px;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.jiqiao-active:hover {
  font-weight: 600;
  color: #FFFFFF !important;
  padding-left: 3px;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.mly-hr-dotted2 {
    border: 0;
    // border-top: 2px dotted #a2a9b6;
    border-top: .5px solid rgba(0,0,0,0.2);
}
.mly-shadow {
  // flex: 1; /* 子元素平分空间 */
  padding: 24px 24px;
  box-shadow: 2px 2px 10px 0px rgba(54,110,244,0.07);
}
.mly-shadow:hover{
  // cursor: pointer;
  cursor: default;
  box-shadow: 4px 4px 15px 0 rgba(54, 110, 244, .2);
}
.mly-full {
  flex: 1; /* 子元素平分空间 */
  padding: 1rem 1rem;
}

.mly-border-radius-30 {
  border-radius: 30px;
}
.mly-border-radius-20 {
  border-radius: 20px;
}
.mly-title {
  font-weight: 600;
  font-size: 20px;
  color: rgba(0,0,0,0.8);
  white-space: nowrap;
}
.mly-subtitle {
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 22px;
  padding: 2px 10px;
  margin: 10px 0;
  display: inline-flex;
  white-space: nowrap;
}
.mly-shangsheng {
  background: linear-gradient( 45deg, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0.8) 100%);
  border-radius: 20px 20px 20px 20px;
  padding: 1px 8px;
  font-size: 12px;
  align-items: center;
}
.mly-detail {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0,0,0,0.8);
  line-height: 22px;
  align-items: center;
  overflow: hidden;
  &-text{
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
    max-width: 200px;
    overflow: hidden;
  }
  &-text-moban{
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
    max-width: 135px;
    overflow: hidden;
  }
  &-text-cj{
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
    max-width: 210px;
    overflow: hidden;
  }
}
.mly-detail-text-tuijian:hover {
  font-weight: 600;
  color: #366EF4;
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.moban-title {
  background: linear-gradient( 90deg, #FFFFFF 0%, rgba(255,255,255,0.55) 100%);
  padding: 2px 10px;
  margin-bottom: 7px;
}
.moban-title:hover {
  background: linear-gradient( 88deg, #868AFA 0%, #A9AFFF 100%);
  color: #FFFFFF;
}
.cj-box {
  flex: 1;
  padding: 0 10px;
  background: linear-gradient( 180deg, #FFFFFF 0%, rgba(255,255,255,0.6) 100%);
  border-radius: 10px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  cursor: pointer;
}
.cj-box:hover {
  background: linear-gradient( 85deg, #4D81FF 0%, #749DFF 100%) !important;
  color: #FFFFFF;
}
.duanpian-moban:hover {

}
.cj-box:hover img, .duanpian-moban:hover .duanpian-moban-hot {
    transform: scale(1.2); /* 放大 1.2 倍 */
    transition: transform 0.3s ease-in-out;
}
.cj-box .tubiao {
  width: 56px;
  height: 56px;
  img {
    // width: 64px;
    height: 56px;
  }
}
.cj-title {
  font-size: 14px;
  text-wrap: nowrap;
}
.cj-zishu {
  font-size: 10px;
  color: #749DFF;
  text-wrap: nowrap;
}
.cj-title-chaishu {
  font-size: 14px;
  color: rgba(255,255,255,1);
}
.cj-zishu-chaishu {
  font-size: 10px;
  color: rgba(255,255,255,0.55);
}
.cj-box .tubiao-chaishu {
  width: 54px;
  height: 64px;
  img {
    margin-top: 10px;
    width: 54px;
    height: 54px;
    margin-left: -5px;
    opacity: .4;
  }
}
/* 小屏下的样式 */
@media (max-width: 768px) {
  .mly-box-x {
    flex-basis: 100%; /* 每个元素占满整个屏幕宽度 */
    min-width: 100%;
  }
  .mly-hide {
    display: none !important;
  }
}
// mly end
</style>
