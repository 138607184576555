<template>
  <div class="writer-box writer-bg" style="">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="myl-news-list global-box-scrollbar" style="background: #F4F5F9;overflow-y: scroll;
    height: 100vh;
    max-width: 1400px;min-height: calc( 100% - 3rem);
    border-radius: 20px;padding: 1.5rem;margin: 0 auto;">

      <div class="myl-news-list-header" style="position: relative;">
        <div class="pointer" style="padding: 0.3rem 1rem 0 0;position: absolute;left: 0;top: 0;" @click="goBack">
          <img src="@/assets/icon-zuojiantou.png" style="height: 40px;" >
        </div>
        <div style="max-width: 700px; margin: 0 auto;">
          <div class="news-goback"
            style="font-weight: 600; font-size: 20px; color: #000000;padding-top: 1rem;margin-bottom: 1rem;">
            {{ data.title}}
          </div>
          <div style="color: rgba(0,0,0,0.4);font-size: 14px;">
            <span>作者：{{ data.author }}</span>
            <span style="padding: 0 1rem;">|</span>
            <span>发布时间：{{ data.updateTime }}</span>
          </div>
        </div>
      </div>

      <div class="myl-news-list-header-phone" style="">
        <div class="d-flex flex-y-center">
          <div class="pointer" style="" @click="goBack">
            <img src="@/assets/icon-zuojiantou.png" style="height: 40px;" >
          </div>
          <div class="news-goback"
            style="font-weight: 600; font-size: 20px; color: #000000;padding-top: 1rem;margin-bottom: 1rem;margin-left: 6px;">
            {{ data.title}}
          </div>
        </div>
        <div style="">
          <div style="color: rgba(0,0,0,0.4);font-size: 14px;">
            <span>作者：{{ data.author }}</span>
            <span style="padding: 0 1rem;">|</span>
            <span>发布时间：{{ data.updateTime }}</span>
          </div>
        </div>
      </div>


      <div class="myl-news-list-content"
       style="min-height: calc( 100% - 3rem);
       max-width: 700px; margin: 2rem auto;font-size: 14px; margin-bottom: 5rem;
       line-height: 28px;"
       v-html="data.content">

      </div>

<div class="myl-news-box-footer d-flex flex-center-x">
  <div class=""
    @click.stop="copyShareText"
    style="text-align: right;padding: 1rem 0;position: fixed;bottom: 4px;border-top: 1px solid rgba(0,0,0,0.05);
    max-width: 700px; background: #F4F5F9;width: 100%;">
    <i class="el-icon-share pointer gongneng-fenxiang" style="
    padding: 8px;
    border-radius: 6px;"></i>
  </div>
</div>


    </div>
  </div>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'NewsList',
  components: {
    HeadNavWriter
  },
  data() {
    return {
      enums: {},
      data: [],
    }
  },
  computed: {
  	...mapState(['user', 'story', 'dialogShowCostomContactFlag', 'activeTemplate', 'storyTiaojian']),
  },
  mounted() {
    this.handlePage()
  },
  beforeDestroy() {

  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogDpFlag', 'setDialogShowCostomContactFlag', 'setActiveTemplate']),
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.enums = result
      })
    },
    goBack() {
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        // 如果没有历史记录，可以跳转到首页或其他页面
        this.$router.push('/');
      }
    },
    handlePage() {
      let id = this.$route.params.id;
      if(!id){
        return false;
      }
      ArticleApi.view(id).then(res => {
        let result = res.data.data;
        this.data = result
      })
    },
    async copyShareText() {
      let id = this.$route.params.id;
      if(!id){
        return false;
      }
      let text = document.location.href
      const textArea = document.createElement('textarea');
      textArea.value = text.trim();
      textArea.style.position = 'fixed';  // 防止出现滚动条
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.$message.success('文章链接复制成功，发送给好友吧！')
        } else {
          this.$message.error('复制时出错')
        }
      } catch (err) {
        this.$message.error('复制时出错')
      }
      document.body.removeChild(textArea);
    }
  }
}
</script>
<style>
.myl-news-list-content img {
  width: 100%;
}
.myl-news-list-content {
  line-height: 1.5rem;
}
.myl-news-list-content p{
  padding: 0;
  margin: 0;
  letter-spacing: .2rem;
}
.gongneng-fenxiang:hover {
  background: #E5E7FC;
}
</style>
<style scoped lang="scss">
.myl-news-box{
  border-radius: 20px;
  background: #FFFFFF;
}
@media screen and (min-width: 501px) {
  .myl-news-list-header-phone {
      display: none;
  }
}
@media screen and (max-width: 500px) {
  .myl-news-list-header {
      display: none;
  }
}

</style>
