import request from '@/utils/request'

const url = '/auth-service/im_messages'

export function page(params) {
  return request({
    url: `${url}/page`,
    method: 'get',
    params: params
  })
}

export function view(id) {
  return request({
    url: `${url}/${id}`,
    method: 'get',
    params: {}
  })
}

export function create(params) {
  return request({
    url: `${url}`,
    method: 'post',
    params: params
  })
}

export function update(params) {
  return request({
    url: `${url}/${params.id}`,
    method: 'put',
    params: params
  })
}

export function remove(id) {
  return request({
    url: `${url}/${id}`,
    method: 'delete'
  })
}

export function enabled(params) {
  return request({
    url: `${url}/enabled/${params.id}/${params.enabled}`,
    method: 'post'
  })
}

export function stream(message) {
  return request({
    url: `/auth-service/api/v1/stream?prompt='hello,ai.'`,
    method: 'get',
    params: {},
    headers: {
      contentType: "application/json",
      responseType: 'stream'
    }
  })
}

export function streamWorkflow(message) {
  return request({
    url: `/auth-service/api/v1/stream?prompt='hello,ai.'`,
    method: 'get',
    params: {},
    headers: {
      contentType: "application/json",
      responseType: 'stream'
    }
  })
}

export function llmStop(params) {
  return request({
    url: `${url}/stop_workflow/${params.processInstanceId}`,
    method: 'post',
    params: params
  })
}
