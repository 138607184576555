<template>
  <div class="writer-box writer-bg" style="">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="myl-news-list global-box-scrollbar" style="background: #F4F5F9;overflow-y: scroll;
    height: 100vh;
      max-width: 1400px;min-height: calc( 100% - 5rem);
      border-radius: 20px;padding: 1.5rem;margin: 0 auto;">
      <div class="myl-news-list-header d-flex flex-y-center">
        <div class="pointer" style="padding: 0.3rem 1rem 0 0;" @click="$router.push('/home')">
          <img src="@/assets/icon-home.png" style="height: 40px;" >
        </div>
        <div style="font-weight: 600; font-size: 20px; color: #000000;">
            {{ articleGroupLabel}}
        </div>
      </div>
      <div class="myl-news-list-content masonry" style="margin-top: 1rem;">

        <div
          @click.stop="$router.push('/newsdetail/'+item.id)"
          class="myl-news-box masonry-item pointer mly-shadow" v-if="data.length>0"
          style="box-sizing: border-box;"
          v-for="(item,index) in data" :key="index" >

          <div class="myl-news-box-header d-flex flex-y-center"

            style="padding: 1rem 1rem;gap: 16px;color: rgba(0, 0, 0, .9);">
            <div class="avatar" v-if="item.avatarUrl">
              <img :src="item.avatarUrl" style="width: 44px;height: 44px;border-radius: 50%;" />
            </div>
            <div class="info" style="font-size: 14px;">
              <div class="title"
                  style="text-wrap: nowrap;
                  overflow: hidden;
                  font-size: 16px;
                  font-weight: 600;
                  text-overflow: ellipsis;
                  width: 180px;">
                {{item.title}}
              </div>
              <div class="publish-time" style="margin-top: .5rem;color: rgba(0, 0, 0, .4);font-size: 14px;">
                {{item.updateTime}}
              </div>
            </div>
          </div>
          <div class="myl-news-box-cover">
            <img :src="item.picUrl" style="width: 100%;" />
          </div>
          <div class="myl-news-box-desc" style="padding: 0rem 1rem;font-size: 14px;line-height: 1.5rem;" v-html="item.remark">

          </div>
          <div class="myl-news-box-footer d-flex"

            style="padding: 0rem 1rem;justify-content: flex-end;">
            <i class="el-icon-share pointer gongneng-fenxiang"
               @click.stop="copyShareText(item)"
               style="padding: 8px;border-radius: 6px;">
             </i>
          </div>
        </div>

        <el-row>
          <el-col>
            <div class="pagination">
              <el-pagination background
                hide-on-single-page
                :page-sizes="paginations.pages"
                :page-size="paginations.size"
                :layout="paginations.layout"
                :total="paginations.total"
                :current-page.sync="paginations.current"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
              />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'NewsList',
  components: {
    HeadNavWriter
  },
  data() {
    return {
      enums: {},
      data: [],
      articleGroupLabel: '',
      queryForm: {
        fromType: 'writer-web',
        articleGroup: '',
        editorFlag: '',
        current: 1,
        size: 20
      },
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        // sizes: [20], // 每页行数 调整
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  computed: {
  	...mapState(['user', 'story', 'dialogShowCostomContactFlag', 'activeTemplate', 'storyTiaojian']),
  },
  mounted() {
    this.handleEnum()
  },
  beforeDestroy() {

  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogDpFlag', 'setDialogShowCostomContactFlag', 'setActiveTemplate']),
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.enums = result
        this.handlePage()
      })
    },
    async copyShareText(item) {
      let articleGroup = this.$route.params.articleGroup;
      if(!articleGroup){
        return false;
      }
      let text = window.location.protocol + '/' + document.location.host + '/newsdetail/' + item.id
      const textArea = document.createElement('textarea');
      textArea.value = text.trim();
      textArea.style.position = 'fixed';  // 防止出现滚动条
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.$message.success('文章链接复制成功，发送给好友吧！')
        } else {
          this.$message.error('复制时出错')
        }
      } catch (err) {
        this.$message.error('复制时出错')
      }
      document.body.removeChild(textArea);
    },
    handlePage(current) {
      if (current) {
        this.paginations.current = current
      }
      this.queryForm.current = this.paginations.current
      this.queryForm.size = this.paginations.size

      this.queryForm.articleGroup = this.$route.params.articleGroup;

      if(this.queryForm.articleGroup == ''){
        return false;
      }
      this.articleGroupLabel = this.enums.ArticleGroupEnum.filter(i=>i.name == this.queryForm.articleGroup)[0].desc
      // 获取表格数据
      ArticleApi.page(this.queryForm).then(res => {
        let result = res.data.data;
        this.data = result.records
        this.paginations.total = Number(result.total)
        this.paginations.current = Number(result.current)
        this.paginations.size = Number(result.size)
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>
<style>
.el-pagination.is-background .el-pager li {
  background: #FAFAFD !important;
}
.el-pagination.is-background .el-pager > .active {
  background-color: #0052D9 !important;
}
.gongneng-fenxiang:hover {
  background: #E5E7FC;
}
.mly-shadow {
  box-shadow: 2px 2px 10px 0px rgba(54,110,244,0.07);
}
.mly-shadow:hover{
  cursor: pointer;
  box-shadow: 4px 4px 15px 0 rgba(54, 110, 244, .2);
}
</style>
<style scoped lang="scss">
.myl-news-box {
  border-radius: 20px;
  background: #FFFFFF;
  // width: calc( (100% - 48px) / 4);
}
/* 小屏下的样式 */
@media (min-width: 768px) {
  .masonry {
    column-count: 4; /* 设置列数 */
    column-gap: 10px; /* 设置列间距 */
  }
  .masonry-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px; /* 设置元素间距 */
    break-inside: avoid; /* 防止元素被分割到不同的列 */
    // background-color: #ffffff;
    padding: 10px;
    box-sizing: border-box;
  }
}
@media (max-width: 767px) {
  .masonry {
    column-count: 1; /* 设置列数 */
    column-gap: 10px; /* 设置列间距 */
  }
  .masonry-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px; /* 设置元素间距 */
    break-inside: avoid; /* 防止元素被分割到不同的列 */
    // background-color: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
  }
}
</style>
