import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source"
import { Message, Loading } from 'element-ui'
import store from '../store'
import { EventBus } from '@/utils//eventbus.js';
import { duration } from "moment/moment";
const sse = function(workflowId, params, msgCallback) {
  return new Promise((resolve, reject) => {
    console.log('sse :: workflowId=', workflowId);
    let cb = msgCallback || function(msg){

    }
    let startTime = new Date().getTime()
    let msg = ''
    let tips = '糟糕！脑电波链接失败！被神秘信号干扰，请尝试重新生成，或稍后重试！' //失败
    let url = process.env.VUE_APP_BASE_API_URL+`auth-service/im_messages/stream_workflow/${workflowId}`
    params.platformCategory = 'WRITER_WEB'
    // params.devFlag = true
    const controller = new AbortController();
    fetchEventSource(url,
        {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + localStorage.lyToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(params),
            onmessage: (event) => {
                // console.error('event:', event);
                let updateTime = new Date().getTime()
                let single = event.data.replace(/^"|"$/g, '').replace(/data:/g, '')
                if (single.startsWith('ERROR1')) {
                    console.error('Received ERROR:', single);
                    message(single.replace('ERROR1:', ''))
                    reject(single)
                    controller.abort();
                }else if (single.startsWith('ERROR2')) {
                    console.error('Received ERROR:', single);
                    // message(single.replace('ERROR2:', ''))
                    store.dispatch('setDialogShowCostomContactFlag', true)
                    reject(single)
                    controller.abort();
                }else if (single.startsWith('ERROR3')) {
                    console.error('Received ERROR:', single);
                    message(single.replace('ERROR3:', ''))
                    reject(single)
                    controller.abort();
                }else if (single.startsWith('processInstanceId')) {
                    console.warn('Received processInstanceId:', single);
                    localStorage.setItem("processInstanceId", single.split(':')[1])
                }else if (single.startsWith('ERROR4')) {
                  console.error('Received ERROR:', single);
                  message(single.replace('ERROR4:', ''))
                  reject(single)
                  controller.abort();
                }else if (single.startsWith('ERROR:LLM1')) {
                  single = single.replace(/\\"/g, '"');
                  message(single.replace('ERROR:LLM1:', ''))
                  EventBus.$emit('llm-tips', tips);
                  setTimeout(()=>{
                    reject(single)
                    controller.abort();
                  }, 5000)
                }else if (single.startsWith('ERROR:LLM2')) {
                  single = single.replace(/\\"/g, '"');
                  message(single.replace('ERROR:LLM2:', ''))
                  EventBus.$emit('llm-tips', tips);
                  setTimeout(()=>{
                    reject(single)
                    controller.abort();
                  }, 5000)
                }else if (single.startsWith('AGENT_END')) {
                    console.log('AGENT_END:', single);
                    // throw new Error('AGENT_END')
                    resolve(msg)
                    controller.abort();
                }else if (single.startsWith('AGENT_END_HUMAN')) {
                    console.log('AGENT_END_HUMAN:', single);
                    controller.abort();
                }else if (single.startsWith('queueName')) {
                    console.warn('queueName:', single);
                    localStorage.setItem("queueName", single.split(':')[1])
                }else {
                  // console.info("onmessage", event);
                  if(single != ''){
                    msg += single;
                    if(workflowId == '1806516321777786881'){// 标题
                      msg = msg?msg.replace(/\“/g, '“').replace(/\”/g, '”').replace(/\\"/g, '"').replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
                    }else{
                      msg = msg?msg.replace(/\“/g, '“').replace(/\”/g, '”').replace(/\\"/g, '"').replace(/\\n/g, '\n'):"";
                    }
                    msgCallback(msg)
                  }
                  if(workflowId != '1806516321777786881'){// 标题
                      let duration = updateTime - startTime
                      handleTips(msg, duration)
                  }
                }
            },
            onerror: (error) => {
                console.info("onerror", error);
                controller.abort();
            },
            onopen: (response) => {
                console.info("onopen", response);
            },
            onclose: (response) => {
                console.info("onclose", response);
                controller.abort();
            },
            openWhenHidden: true,
            signal: controller.signal
        }
    );
  })
}
const handleTips = function(msg, duration){
  let temp = msg.replace(/^\s*\n/gm, "").trim()
  let tipsSuccess = '欧吼～脑电波链接成功！正在准备输出' //成功
  if(temp != ''){
    EventBus.$emit('llm-tips', tipsSuccess);
    return false;
  }
  // console.info("duration", duration, msg);
  let tips = ''
  // 60s、提示用户：糟糕！脑电波链接失败！被神秘信号干扰，请尝试重新生成，或稍后重试！ 失败
  // 50S、提示用户：码哩码哩哄～(╯‵□′)╯︵┻━┻（持续念咒中···）
  // 40S、提示用户：码哩码哩哄～(╯°□°）╯︵ ┻━┻（念咒中···）
  // 30S、提示用户：共脑中(●--●)···
  // 20S、提示用户：码哩正在读取你的想法(●°u°●)​ 」···
  // 10S、提示用户：码哩正在打开脑电波，接受信号中O(∩_∩)O~···
  // if(duration > 60*1000){
  //   tips = '糟糕！脑电波链接失败！被神秘信号干扰，请尝试重新生成，或稍后重试！' //失败
  //   tips = '欧吼～脑电波链接成功！正在准备输出···' //成功
  // }
  if(duration > 40*1000){
    tips = '码哩码哩哄～(╯‵□′)╯︵┻━┻（持续念咒中）'
  }else if(duration > 30*1000){
    tips = '码哩码哩哄～(╯°□°）╯︵ ┻━┻（念咒中）'
  }else if(duration > 20*1000){
    tips = '共脑中(●--●)'
  }else if(duration > 10*1000){
    tips = '码哩正在读取你的想法(●°u°●)​ 」'
  }else if(duration > 0){
    tips = '码哩正在打开脑电波，接受信号中O(∩_∩)O~'
  }
  EventBus.$emit('llm-tips', tips);
}
const message = function(msg){
  Message({
    dangerouslyUseHTMLString: true,
    message: msg,
    type: 'error',
    duration: 8 * 1000
  })
}
export default sse
