<template>
<div
  v-show="showZhilingPanelFlag" style="z-index: 8001;"
  class="mly-zhiling mly-container-gen4 mly-border-radius-20"
  :class="{'mly-container-pc': !isMobile,'mly-container-pc-cp': !isMobile&&fromChannel=='changpian', 'mly-container-mobile': isMobile}"
  :style="{'height': dialogHeight + 'px', 'top': dialogTop + 'px'}">
  <div class="mly-zhiling-title d-flex flex-between" style="align-items: center;margin-top: 10px;">
    <div style="font-weight: 600; font-size: 20px; color: #000000;">快捷指令</div>
    <div class="pointer image-switcher image-switcher-zhiling" @click="setShowZhilingPanelFlag(false)">

    </div>
  </div>
  <div class="mly-zhiling-search" style="margin: 10px 0;">
    <div style="margin: 5px 0;">
      <el-input
        @input="handleZhiling"
        class="search-title"
        placeholder="请输入关键字"
        prefix-icon="el-icon-search"
        v-model="form.fieldLabel"
        clearable>
      </el-input>
    </div>
    <div style="display: flex;justify-content: flex-end;margin-right: 4px;">
      <el-select style="" class="custom-select"
          v-model="form.parentId"
          filterable
          size="mini"
          placeholder="分组">
        <el-option
          v-for="(item,index) in zhilingGroups"
          @click.native.stop="handleZhiling"
          :label="item.fieldLabel"
          :key="item.id"
          :value="item.id"
        />
      </el-select>
    </div>
  </div>
  <div class="mly-zhiling-list mly-scrollbar"
    style="display: flex;flex-direction: column;gap: 16px;flex: 1;overflow: auto;">
    <div class="mly-zhiling-item pointer"
          style="border-radius: 10px; background-color: #ffffff;padding: 12px;"
        v-for="(item,index) in zhilings" :key="index"
        @click.stop="chooseZhiling(item)"
      >
      <div class="d-flex flex-between" style="margin-bottom: 10px;">
        <div class="mly-ellipsis"
          style="font-weight: 600;
                font-size: 14px;
                color: rgba(0,0,0,0.9);">
          {{item.fieldLabel}}
        </div>
        <div class="mly-text-nowrap"
          style="background: #F2F3FF;border-radius: 3px;padding: 2px;
              font-weight: 400;
              font-size: 10px;
              color: #0052D9;">
          {{item.parent?item.parent.fieldLabel:''}}
        </div>
      </div>
      <div class="mly-ellipsis2"
       style="font-weight: 400;
              font-size: 10px;
              color: rgba(0,0,0,0.9);
              line-height: 16px;">
        {{item.fieldValue}}
      </div>
    </div>
  </div>
</div>
</template>
<script>
import * as ZhishikuApi from '@/api/zhishiku'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'ZhilingSelect',
  props: {
    fromChannel: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  data() {
    return {
      dialogHeight: 0,
      dialogTop: 0,
      zhilings: [],
      zhilingGroups: [],
      form:{
        fieldLabel: '',
        ownerGroup: 'OFFICIAL',
        fromType: 'writer-client-zhiling-l2',
        zhishiGroup: 'INSTRUCTION',
        publishFlag: true,
        size: 500,
        parentId: ''
      },
      params:{
        ownerGroup: 'OFFICIAL',
        fromType: 'writer-client-zhiling',
        zhishiGroup: 'INSTRUCTION',
        publishFlag: true,
        size: 500,
        parentId: '0'
      }
    }
  },
  beforeDestroy() {

  },
  mounted() {
    this.handleZhilingGroup()
    this.handleZhiling()
  },
  computed: {
  	...mapState(['showZhilingPanelFlag', 'enums']),
    isMobile(){
      return window.innerWidth <= 768 ? true : false;
    }
  },
  methods: {
    ...mapMutations(['setShowZhilingPanelFlag']),
    handleZhiling(){
      ZhishikuApi.page(this.form).then(res => {
        let result = res.data.data;
        this.zhilings = result.records
      })
    },
    handleZhilingGroup(){
      ZhishikuApi.page(this.params).then(res => {
        let result = res.data.data;
        result.records.unshift({id: '0', fieldLabel: '全部'})
        this.zhilingGroups = result.records
      })
    },
    chooseZhiling(item){
      const targetText = item.fieldValue
      const parent = this.$parent
      console.log('parent:', parent, parent.$refs.myTextarea);
      // el-input tpye=textarea
      const textarea = parent.$refs.myTextarea.$el.querySelector('textarea');

      let text = textarea.value

      if(textarea.value.includes(targetText)){
        this.$message.warning('指令已存在')
        return false
      }
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const before = text.slice(0, start);
      const after = text.slice(end);
      // console.log('start', start, 'end', end, 'before', before, 'after', after);
      // let startEnter = start==0?'':'\n'
      textarea.value = before + '\n' + targetText + '\n' + after;
      // 重新设置光标位置到插入内容之后
      textarea.selectionStart = textarea.selectionEnd = start + targetText.length + 2;
      let contentRequired = ''
      if(this.fromChannel == 'changpian'){
        parent.storyChapterOutlineRequired = textarea.value
        contentRequired = parent.storyChapterOutlineRequired
      }else{
        parent.currentStoryOutline.contentRequired = textarea.value
        contentRequired = parent.currentStoryOutline.contentRequired
      }

      console.log('targetText', targetText, '\ncontentRequired', contentRequired, '\ntext', text);

      // 手动触发 input 事件
      // const event = new Event('input', { bubbles: false });
      // textarea.dispatchEvent(event);
      this.$message.success('指令已添加，可继续添加或关闭')
      this.scrollToCursor()
    },
    handleOpen() {
      this.$nextTick(() => {
        if(this.showZhilingPanelFlag){
          this.setShowZhilingPanelFlag(false)
          return false;
        }
        if(this.fromChannel == 'changpian'){
          // 没有 dialog, 父元素与其他处理方式不一样
          let vh = window.innerHeight
          console.log('vh', vh);
          this.dialogTop = vh * 0.08
          this.dialogHeight = this.isMobile?vh * 0.8:vh * 0.6
        }else{
          // 方法1：获取整个对话框的高度
          const dialog = this.$parent.$refs.myDialog.$el.querySelector('.el-dialog');
          console.log('整个对话框高度:', dialog.offsetHeight);
          this.dialogHeight = this.isMobile?window.innerHeight*0.8:dialog.offsetHeight

          // 方法2：获取内容区域高度
          const body = this.$parent.$refs.myDialog.$el.querySelector('.el-dialog__body');
          console.log('内容区域高度:', body.clientHeight);

          // 方法3：获取可见视口高度
          console.log('视口高度:', window.innerHeight);

          // 方法1：使用getBoundingClientRect
          const rect = dialog.getBoundingClientRect();
          console.log('方法1 - 距离顶部:', rect.top);

          // 方法2：使用offsetTop（相对定位父级）
          console.log('方法2 - offsetTop:', dialog.offsetTop);
          this.dialogTop = dialog.offsetTop
          // 方法3：计算相对于文档的绝对位置
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const absoluteTop = rect.top + scrollTop;
          console.log('方法3 - 绝对位置:', absoluteTop);
        }
        this.setShowZhilingPanelFlag(true)

      });
    },
    scrollToCursor() {
      const parent = this.$parent
      const textarea = parent.$refs.myTextarea.$el.querySelector('textarea');
      if (!textarea) return

      // 获取光标位置
      const cursorPosition = textarea.selectionStart

      // 创建一个临时元素来计算光标位置的高度
      const textBeforeCursor = textarea.value.substring(0, cursorPosition)
      const tempDiv = document.createElement('div')
      tempDiv.style.cssText = `
        position: absolute;
        top: -9999px;
        left: -9999px;
        width: ${textarea.clientWidth}px;
        padding: ${getComputedStyle(textarea).padding};
        font-size: ${getComputedStyle(textarea).fontSize};
        font-family: ${getComputedStyle(textarea).fontFamily};
        line-height: ${getComputedStyle(textarea).lineHeight};
        white-space: pre-wrap;
        word-wrap: break-word;
      `
      tempDiv.textContent = textBeforeCursor
      document.body.appendChild(tempDiv)

      // 计算光标位置的高度
      const cursorHeight = tempDiv.clientHeight
      document.body.removeChild(tempDiv)

      // 计算滚动位置
      const visibleHeight = textarea.clientHeight
      const scrollTop = textarea.scrollTop

      // 如果光标不在可视区域内，则滚动到光标位置
      if (cursorHeight < scrollTop || cursorHeight > scrollTop + visibleHeight) {
        // 将光标位置居中显示
        textarea.scrollTop = Math.max(0, cursorHeight - visibleHeight / 2)
      }

      if(this.isMobile){
        this.setShowZhilingPanelFlag(false)
      }
    }
  }
}
</script>

<style type="text/css">
.animate-up {
  transition: all 5s ease-in-out;
  transform: translateY(-25px);
}
.el-select-dropdown {
  border-radius: 10px !important;
  z-index: 9001 !important;
}
.el-select-dropdown__item {
  font-size: 12px;
}

</style>
<style scoped lang="scss">

// mly
.mly-container-gen4 {
  padding: 20px;
  position: absolute;
  margin-left: 10px;
  z-index: 8001 !important;
  background: url('../../assets/zhiling-youtu.png'), linear-gradient( 180deg, #EBF1FE 0%, #BED1FA 100%);
  background-repeat: no-repeat;
  background-position: left top;
  display: flex;
  flex-direction: column;
}
.mly-zhiling-item:hover {
  border: 1px solid #366EF4;
}
.search-title {
  /deep/ .el-input__inner {
    border-radius: 20px;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
  }
  /deep/ .el-input__suffix {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  /deep/ .el-input__prefix .el-input__icon {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding-left: 3px;
  }
}
.custom-select {
  width: 4.5rem;
  margin-right: -4px;
  /deep/ .el-input__inner {
    border-radius: 20px;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  /deep/ .el-input__suffix {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  /deep/ .el-input__inner::placeholder {
    color: rgba(0,0,0,1);
  }
}
/deep/ .el-select .el-input .el-select__caret {
  color: rgba(0,0,0,1);
}

.mly-border-radius-20 {
  border-radius: 20px;
}
.image-switcher {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: cover;
  transition: background-image 0.3s; /* 平滑过渡效果 */
  &-zhiling {
    background-image: url('../../assets/zhiling-zhankai.png');
  }
  &-zhiling:hover {
    background-image: url('../../assets/zhiling-zhankai-s.png');
  }
}

/* 全局滚动条轨道的padding */
.mly-scrollbar::-webkit-scrollbar-track {
  background-color: transparent !important;

  border: none;
  box-shadow: none;
}
/* 滚动条的样式 */
.mly-container::-webkit-scrollbar {
  width: 6px;
  background-color: transparent !important;
}
.mly-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 1);
}
/* 滚动条角落（右下角交汇处） */
.mly-container::-webkit-scrollbar-corner {
  background-color: transparent; /* 角落透明 */
}

.mly-container-pc {
  left: 70%;
  width: 20% !important;
}
.mly-container-pc-cp {
  left: calc( 80% - 1rem) !important;
}
.mly-container-mobile {
  // position: relative;
  width: 90% !important;
  left: 2.5% !important;
}

// mly end
</style>
