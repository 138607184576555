<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="writer-box-content" style="position: relative;">

      <!-- 头部 -->
      <div class="d-flex flex-y-center px-5p">
        <div class="pointer" style="padding: 0.3rem 1rem 0 0;" @click="$router.push('/home')">
          <i class="" style="font-size: 1.5rem;"><img src="@/assets/icon-home.png" width="40" height="40" alt=""></i>
        </div>
        <div class="writer-box-content-title" style="font-weight: 600; font-size: 20px;flex: 1; color: #000000;line-height: 24px;">
          <div class="writer-box-content-title-input d-flex flex-between flex-y-center">
            <div class="d-flex flex-y-center" style="width: auto;margin-right: 1rem;flex:1;">
              <div style="" class="image-switcher" @click="focusTitle"></div>
              <el-input class="custom-title" ref="customtitle" v-model="story.title" @blur="handleUpdateTitle" placeholder="起一个炸裂吸睛的标题吧~">
              </el-input>
            </div>
          </div>
        </div>
        <div class="" style="float: right;">
          <el-button class='custtom-button1' style="flex: 1;border-radius: 10px;"  :size="$root.buttonSize" type="default" round icon="el-icon-setting" @click="setShowChangpianDialogFlag(true)" :loading="isLoading" :disabled="isLoading" >{{hideFlag?'':'故事设置'}}</el-button>
        </div>
      </div>
<!-- hidden-xs-only -->
      <div class="writer-box-content-work d-flex" style="padding-bottom: .6rem;">
        <!-- <div class="writer-box-content-work-left" v-show="!hideFlagMLZW"> -->
        <div class="writer-box-content-work-outline px-5p d-flex" style="flex-direction: column;" v-show="!hideFlagML" :style="{'flex':boxStyle.mlFlex}">
            <div class="d-flex flex-between" style="margin-right: 1rem;padding-bottom: .5rem;">
                <el-button style="flex: 1;border-radius: 10px;" class="" size="small" type="primary" icon="el-icon-document-add" @click="handleCreateChapter(0)" :loading="isLoading" :disabled="isLoading">新建章</el-button>
                <el-button style="flex: 1;border-radius: 10px;" class="" size="small" type="default" icon="el-icon-circle-plus-outline" @click="handleCreateChapter(1)" :loading="isLoading" :disabled="isLoading">新建卷</el-button>
            </div>
            <div class="outline-list-box global-box-scrollbar" style="flex: 1;">
              <el-tree
                ref="tree"
                :data="treeData"
                node-key="id"
                default-expand-all
                :highlight-current="true"
                @node-click="handleNodeClick"
                @node-drag-start="handleDragStart"
                @node-drag-enter="handleDragEnter"
                @node-drag-leave="handleDragLeave"
                @node-drag-over="handleDragOver"
                @node-drag-end="handleDragEnd"
                @node-drop="handleDrop"
                draggable
                :allow-drop="allowDrop"
                :allow-drag="allowDrag">
                <div class="custom-tree-node d-flex flex-y-center flex-between"
                  v-on:hover="nodeHover"
                  style="width: 100%;font-weight: 400;margin-right: 1rem;font-size: 12px;color: rgba(0,0,0,0.6);"
                  slot-scope="{ node, data }">
                    <!-- 重命名   :class="{'el-icon-document': node.level==2, 'el-icon-files': node.level==1}" -->
                    <span @click.stop="void(0)" style="width: 14rem;background: #F2F3FF;position: absolute;
                      border-radius: 10px;
                      border: 1px solid #0052D9;z-index: 9007199254740991;left: 0rem;top: 1rem;padding: 1rem;"
                    v-if="editFlag && currentNode.data && currentNode.data.id == node.data.id">
                      <el-input class="renamebox" type="textarea" v-model="nameInputValue"
                        maxlength="20" show-word-limit clearable
                        style="">
                      </el-input>
                      <div class="" style="text-align: center; margin-top: 1rem;">
                          <el-button class="custom-button" style="padding: .5rem 1rem;" size="mini" type="default" @click.stop="editFlag=false">取消</el-button>
                          <el-button class="custom-button" style="padding: .5rem 1rem;" size="mini" type="primary" :disabled="nameInputValue.trim==''" @click.stop="handleRenameOk(node)">确定</el-button>
                      </div>
                    </span>

                    <span :class="{'el-icon-document': node.level==2, 'el-icon-files': node.level==1}"
                    v-else>{{ data?data.title:'' }}</span>

                    <span v-if="currentNode.data && currentNode.data.id == node.data.id">
                      <el-dropdown @click.native.stop trigger="hover" >
                        <span><img style="width: 1rem;" src="@/assets/icon-gengduo.png" /></span>
                        <!-- 卷 -->
                        <el-dropdown-menu v-if="currentNode.level == 1" slot="dropdown" style="width: 13.8rem;margin-left: 1.6rem;" >
                          <div class="" style="padding-left: 1.1rem;font-weight: 400;">
                            <div class="" style="font-size: 14px;color: rgba(0,0,0,0.9);">
                              {{data?data.title:''}}
                            </div>
                            <div style="font-size: 14px;padding: .5rem 0;color: rgba(128,128,128,0.55);">
                              共{{node.parent.childNodes.length}}章
                            </div>
                            <div style="font-size: 14px;color: rgba(128,128,128,0.55);">
                              共{{currentNode.childNodes.reduce((accumulator, current) => {
                                let content = current.data.contentVersion && current.data.contentVersion.content ? current.data.contentVersion.content:''
                                return accumulator + content.length;
                              }, 0)}}字
                            </div>
                          </div>
                          <el-dropdown-item>
                            <div class="d-flex flex-y-center pointer" @click.stop="handleRename(node)">
                              <!-- <img style="width: .8rem;" src="@/assets/writer-delete.png"/> -->
                              <span>重命名</span>
                            </div>
                          </el-dropdown-item>
                          <el-dropdown-item>
                            <div class="d-flex flex-y-center pointer" @click.stop="handleCreateChapter(0)">
                              <!-- <img style="width: .8rem;" src="@/assets/writer-delete.png"/> -->
                              <span>新建章节</span>
                            </div>
                          </el-dropdown-item>
                          <el-dropdown-item>
                            <div class="d-flex flex-y-center pointer" @click.stop="handleRemove(node)">
                              <!-- <img style="width: .8rem;" src="@/assets/writer-delete.png"/> -->
                              <span>删除本卷</span>
                            </div>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                        <!-- 章节 -->
                        <el-dropdown-menu  v-if="currentNode.level == 2" slot="dropdown" style="width: 13.8rem;margin-left: 1.6rem;" >
                            <div class="" style="padding-left: 1.1rem;font-weight: 400;">
                              <div class="" style="font-size: 14px;color: rgba(0,0,0,0.9);">
                                {{data?data.title:''}}
                              </div>
                              <div style="font-size: 14px;padding: .5rem 0;color: rgba(128,128,128,0.55);">
                                <span>创建：</span>
                                <span>{{data.createTime.replace('-', '/')}}</span>
                              </div>
                              <div style="font-size: 14px;color: rgba(128,128,128,0.55);">
                                共{{(node.data.contentVersion&&node.data.contentVersion.content?node.data.contentVersion.content.length:0)}}字
                              </div>
                            </div>
                            <el-dropdown-item>
                              <div class="d-flex flex-y-center pointer" @click.stop="handleRename(node)">
                                <!-- <img style="width: .8rem;" src="@/assets/writer-delete.png"/> -->
                                <span>重命名</span>
                              </div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                              <div class="d-flex flex-y-center pointer" @click.stop="handleRemove(node)">
                                <!-- <img style="width: .8rem;" src="@/assets/writer-delete.png"/> -->
                                <span>删除</span>
                              </div>
                            </el-dropdown-item>
                          </el-dropdown-menu>
                      </el-dropdown>
                    </span>
                    <span style="" v-else>
                      {{node.level==2?(node.data.contentVersion&&node.data.contentVersion.content?node.data.contentVersion.content.length:0)+'字':node.childNodes.length+'章'}}
                    </span>
                </div>
              </el-tree>
            </div>
            <WriteTips />
        </div>
        <!-- </div> -->

        <div class="writer-box-content-work-editor px-5p" v-show="!hideFlagZW" :style="zwmlStyle['zwStyle']">
          <div style="font-weight: 400;margin-bottom: 1rem;
              vertical-align: middle;
              font-size: 14px;
              color: #242424;">
            <span style="font-weight: 600;
              font-size: 16px;
              color: #366EF4;">正文</span>
            <span style="margin: 0 .5rem;">{{currentParentNode.data?currentParentNode.data.title:'-'}}</span>
            <span>{{currentNode.data?currentNode.data.title:''}}</span>
            <span class="pointer" @click="downloadFile2" style="float: right;margin-left: 0.8rem;">
              <img style="width: 1.25rem;" src="@/assets/icon-daoru.png"/>
            </span>
            <span class="pointer" @click="copyToClipboard" style="float: right;margin-left: 0.8rem;">
              <img style="width: 1.25rem;" src="@/assets/icon-fuzhi.png"/>
            </span>
            <span class="pointer" v-show="hideFlag" @click="showDG" style="float: right;color:#0052D9;font-size: 14px;">本章大纲</span>
          </div>
          <!-- @cut.native="changeContentInput"
            @paste.native="changeContentInput" -->
          <el-input
            @input="changeContentInput"
            v-if="!showSkeleton"
            id="msgbox"
            class="msgbox global-box-scrollbar"
            type="textarea"
            v-model="storyContent"

            show-word-limit
            style="font-weight: 600;font-size: 16px;color: #000000;line-height: 24px;flex: 1;">
          </el-input>

          <LlmSkeleton v-if="showSkeleton" />

          <div class="content-version d-flex flex-between flex-v-center" style="margin: .5rem 0;" v-if="storyContents.length > 0">
            <el-select class="custom-select"
                v-model="storyContentObj.id"
                :filterable="false"
                size="mini"
                :disabled="isLoading"
                placeholder="版本">
              <el-option
                v-for="(itemVersion, index) in storyContents"
                @click.native.stop="contentVersionChange(itemVersion)"
                :key="index"
                :label="'版本 ' + itemVersion.version"
                :value="itemVersion.id"
              />
            </el-select>
            <el-button v-show="hideFlag" class="custom-button" :size="$root.buttonSize" type="primary" round icon="el-icon-refresh" @click="showDG" :loading="isLoading" :disabled="isLoading||currentNode.level==1" >重新生成正文</el-button>
          </div>
        </div>

        <!-- outline -->
        <div v-show="!hideFlagDG" class="writer-box-content-work-current-outline px-5p" :style="zwmlStyle['dgStyle']">
          <div class="writer-box-content-work-current-outline-top">
            <div style="font-weight: 400;
                font-size: 14px;padding: 1rem 1rem;
                color: #242424;">
              <span style="font-weight: 600;
                font-size: 16px;
                color: #366EF4;">大纲</span>
              <span style="margin: 0 1rem;">{{currentParentNode.data?currentParentNode.data.title:'-'}}</span>
              <span>{{currentNode.data?currentNode.data.title:''}}</span>
              <span class="pointer" style="float: right;color: rgba(54,110,244,0.8);font-size: 12px;line-height: 16px;" @click="storyChapterOutline = '';storyChapterOutlineRequired = ''">清空</span>
            </div>
            <div class="" style="font-weight: 400;height: calc( 100% - 14rem);overflow-y: scroll;
              padding: 1rem;background: #FFFFFF;
              border-radius: 10px; margin-left: 1rem;margin-right: 1rem;
              font-size: 14px;line-height: 20px;
              color: #000000;">
              <el-input
                @change="changeChapterOutlineInput"
                v-if="!showSkeleton"
                ref="outlinebox"
                class="outlinebox global-box-scrollbar"
                type="textarea"
                v-model="storyChapterOutline"
                style="font-size: 16px;color: #000000;line-height: 24px;height:78%;">
              </el-input>
            </div>
            <div class="" style="margin: 1rem 1rem;">
              <ZhilingOpen fromChannel="changpian" />
              <el-input
                ref="myTextarea"
                type="textarea"
                class="custom-textarea global-box-scrollbar"
                :rows="5"
                placeholder="可输入本章写作要求，例如指定哪一句要详细展开"
                v-model="storyChapterOutlineRequired"
                style="font-size: 16px;color: #000000;line-height: 24px;height:20%;"></el-input>
            </div>
          </div>

      <div style="display: flex;align-items: center;margin-bottom: 1rem;">
          <el-switch style="" class="mly-switch" style="margin-right: 4px;"
            v-model="aiMasterFlag"
            active-color="#0052d9"
            active-text="AI总编提质"
            inactive-text="">
          </el-switch>
          <el-tooltip placement="top">
            <div slot="content">
              让AI总编指导AI生成正文，可以提高质量，但会额外消耗些许能量
            </div>
            <div style="display: flex; align-items: center; justify-content: center; height: 14px; margin-bottom:-2px">
              <img style="height: 14px;" src="@/assets/tishi-icon.png" />
            </div>
          </el-tooltip>
        </div>


          <div class="" style="text-align: center;">
              <el-button v-if="hideFlag" class="custom-button" :size="$root.buttonSize" icon="el-icon-back" @click="showZW">返回正文</el-button>
              <el-button v-if="storyContents.length == 0" class="custom-button" :size="$root.buttonSize" type="primary" @click="startGenStoryContent(0)" :loading="isLoading" :disabled="isLoading||currentNode.level==1">生成正文</el-button>
              <el-button v-else class="custom-button" :size="$root.buttonSize" type="primary" round icon="el-icon-refresh" @click="startGenStoryContent(1)" :loading="isLoading" :disabled="isLoading||currentNode.level==1" >重新生成正文</el-button>
          </div>
        </div>
      </div>
    </div>
    <StoryChangpianDialog v-if="storyTiaojian.length>0" :enums="enums" channel="genbycp_setting" />

    <div class="pointer" v-show="hideFlagML" @click.stop="showML"
     style="position: absolute;top: 50%;left: 0;padding: 1rem .5rem 1rem 1.5rem;margin-left: -1rem;
        color: #0052D9;font-weight: 400;font-size: 14px;letter-spacing: 0.2em;
        background: #E5E7FC;
        border: 0px solid #0052D9;
        border-radius: 20px;
        writing-mode: vertical-rl;
        text-orientation: mixed;">
          章节目录
        </div>
    <ZhilingSelect v-show="showZhilingPanelFlag" ref="myZhilingPanel" fromChannel="changpian" />
    <div v-show="showZhilingPanelFlag" class="mly-overlay" @click="setShowZhilingPanelFlag(false)"></div> <!-- 添加蒙版层 -->
  </div>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import StoryChangpianDialog from '@/components/StoryChangpianDialog'
import LlmSkeleton from '@/components/LlmSkeleton'
import WriteTips from '@/components/WriteTips'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as ChapterApi from '@/api/chapter'
import * as StoryContentApi from '@/api/storycontent'
import * as StoryContentVersionApi from '@/api/storycontentversion'
import ZhilingSelect from '@/components/zhiling/zhiling-select';
import ZhilingOpen from '@/components/zhiling/zhiling-open';
import sse from '@/utils/llmstream'
import { mapState, mapMutations} from 'vuex'
import { nextTick } from 'vue'
export default {
  name: 'ArticleChangpian',
  components: {
    HeadNavWriter,StoryChangpianDialog,LlmSkeleton,WriteTips,ZhilingSelect,ZhilingOpen
  },
  data() {
    return {
      treeData: [],
      aiMasterFlag: true,
      editFlag: false,
      enums: {},
      titleInputValue: '',
      nameInputValue: '',
      storyChapterOutline: '',
      storyChapterOutlineRequired: '',
      currentNode: {},
      currentParentNode: {},
      currentChapter: {},
      currentVolume: {},
      isLoading: true,
      showSkeleton: true,
      hideFlagML: false,
      hideFlagZW: false,
      hideFlagDG: false,
      hideFlagMLZW: false,
      initMobileFlag: false,
      storyContent: '',
      storyContentObj: {},
      boxStyle: {
        mlFlex: 1,
        zwFlex: 2,
        dgFlex: 1
      }
    }
  },
  computed: {
  	...mapState(['user', 'story', 'storyContents', 'showChangpianDialogFlag', 'storyTiaojian', 'showZhilingPanelFlag']),
    hideFlag() {
      return window.innerWidth <= 768
    },
    zwmlStyle() {
      let showZw = {
        "zwStyle": 'flex: 2;',
        "dgStyle": 'flex: 1;'
      }
      let hideZw = {
        "zwStyle": 'flex: 0;display:none;',
        "dgStyle": 'flex: 3;'
      }
      let mobile = {
        "zwStyle": 'flex: 1;',
        "dgStyle": 'flex: 1;'
      }
      return this.hideFlag ? mobile : this.storyContent && this.storyContent.length > 0 ? showZw : hideZw
    },
    from() {
      return this.$route.query.from;
    }
  },
  mounted() {
    this.handleEnum()
    if(this.showChangpianDialogFlag){

    }else{
      this.handlePageChapter(0)
    }
    this.initMobileStyle()
    console.log('buttonsize', this.$root.buttonSize)
  },
  methods: {
    ...mapMutations(['setStory', 'setStoryContents','setShowChangpianDialogFlag', 'setShowZhilingPanelFlag']),
     initMobileStyle () {
      if(this.initMobileFlag) return;
      let hasContent = this.storyContent && this.storyContent.length>0
      if(this.hideFlag){
        if(hasContent){
          this.hideFlagDG = true
          this.hideFlagZW = false
          this.hideFlagML = true
        }else{
          this.hideFlagDG = false
          this.hideFlagZW = true
          this.hideFlagML = true
        }
      }else{
        if(hasContent){
          this.hideFlagDG = false
          this.hideFlagZW = false
          this.hideFlagML = false
        }else{
          this.hideFlagDG = false
          this.hideFlagZW = true
          this.hideFlagML = false
        }
      }

      // this.hideFlagDG = this.hideFlag && this.storyContent
      this.initMobileFlag = true
      console.log(this.hideFlagML, !!this.storyContent, this.hideFlagZW, this.hideFlagDG)
     },
     showML(){
       // this.hideFlagMLZW = false;
       this.hideFlagML = false;
       this.hideFlagZW = true;
       this.hideFlagDG = true;
       console.log(this.hideFlagML, this.hideFlagZW, this.hideFlagDG)
     },
     showZW(){
       // this.hideFlagMLZW = true;
       this.hideFlagML = true;
       this.hideFlagZW = false;
       this.hideFlagDG = true;
       console.log(this.hideFlagML, this.hideFlagZW, this.hideFlagDG)
     },
     showDG(){
       // this.hideFlagMLZW = true;
       this.hideFlagML = true;
       this.hideFlagZW = true;
       this.hideFlagDG = false;
       console.log(this.hideFlagML, this.hideFlagZW, this.hideFlagDG)
     },
    async copyToClipboard() {
      let text = this.storyContent
      try {
        await navigator.clipboard.writeText(text);
        console.log('已复制到剪贴板: ' + text);
      } catch (err) {
        console.error('复制到剪贴板时出错: ', err);
         // 回退方案，如果navigator.clipboard不可用
         this.fallbackCopyTextToClipboard(text);
      }
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      textArea.style.position = 'fixed';  // 防止出现滚动条
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          console.log('回退方案成功复制到剪贴板');
        } else {
          console.error('回退方案复制失败');
        }
      } catch (err) {
        console.error('回退方案复制时出错: ', err);
      }
      document.body.removeChild(textArea);
    },
    handleDragStart(node, ev) {
      // console.log('drag start', node);
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      // console.log('tree drag enter: ', dropNode.title);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      // console.log('tree drag leave: ', dropNode.title);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      // console.log('tree drag over: ', dropNode.title);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      // console.log('tree drag end: ', dropNode && dropNode.title, dropType);
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // console.log('tree handleDrop: ', draggingNode, dropNode, dropType, ev);
      // 重排序
      let parentId = dropNode.data.parentId
      let pNode = dropNode.parent
      let chapterIds = pNode.childNodes.map((item) => {
        return item.data.id
      })
      ChapterApi.reorder({parentId, chapterIds}).then(res => {
        this.handlePageChapter(1)
      });
    },
    allowDrop(draggingNode, dropNode, type) {
      // console.log('tree allowDrop: ', draggingNode, dropNode, type, dropNode.data.parentId, draggingNode.data.parentId);
      if(dropNode.data.parentId !== draggingNode.data.parentId){
        return false;
      }
      // 禁止拖拽成为根节点的子节点
      if (type === 'next' || type === 'prev') {
        return draggingNode.level === 2;
      }

      return false;
    },
    allowDrag(draggingNode) {
      return draggingNode.level === 2;
    },
    convertToChineseNum(num) {
        var chnNumChar = [
            "零", "一", "二", "三", "四", "五", "六", "七", "八", "九"
        ];
        var chnUnitChar = [
            "", "十", "百", "千", "万", "亿", "兆"
        ];
        var strOutput = "";
        var intTemp = 0;
        var intUnitPos = 0;

        num = Math.abs(num);
        while (num > 0) {
            intTemp = num % 10;
            if (intTemp > 0) {
                strOutput = chnNumChar[intTemp] + chnUnitChar[intUnitPos] + strOutput;
            } else {
                strOutput = chnNumChar[0] + chnUnitChar[intUnitPos] + strOutput;
            }
            num = Math.floor(num / 10);
            intUnitPos++;
        }

        return strOutput === "" ? "零" : strOutput;
    },

    contentVersionChange(item){
      console.log('contentVersionChange', item.id, this.storyContentObj.id);
      this.storyContent = item.content
      // 记录选中版本
      ChapterApi.update({id: this.currentNode.data.id, storyContentVersionId: item.id, fromType: 'gencp_update_using'}).then(res => {
        // this.currentNode.data.storyContentVersionId = item.id
        // this.currentNode.data.contentVersion = item
        // 刷新目录

        // 更新目录 & 刷新版本
        this.handlePageChapter(2)
      })
    },
    changeContentInput(event){
      // console.log('changeContentInput', event);
      this.handleUpdateContent()
    },
    changeChapterOutlineInput(event){
      // console.log('changeInput', event);
      this.handleUpdateOutline()
    },
    handleUpdateContent() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      if (!this.storyContent || !this.storyContent.trim()) {
          // this.$message.error('请输入故事大纲');
          return false;
      }

      StoryContentVersionApi.update({id: this.storyContentObj.id, content: this.storyContent}).then(res => {
        this.handlePageChapter(2)
      })
    },
    handleUpdateOutline() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      if (!this.storyChapterOutline || !this.storyChapterOutline.trim()) {
          // this.$message.error('请输入故事大纲');
          return false;
      }
      console.log('handleUpdateOutline');
      ChapterApi.update({id: this.currentNode.data.id, content: this.storyChapterOutline, contentRequired: this.storyChapterOutlineRequired}).then(res => {
        this.currentNode.data.content = this.storyChapterOutline
        this.currentNode.data.contentRequired = this.storyChapterOutlineRequired
      })
    },
    handleStoryContentMsg(msg){
      let temp = this.replaceLineBeginBreaks(msg).trim()
      console.log('msg=', msg, temp);
      if(temp == ''){
        this.showSkeleton = true
        this.storyContent = ' '
        return false
      }
      this.storyContent = msg
      this.showSkeleton = false
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    findPreParent(parent){
      //跨卷内容处理
      console.log('parent.parent', parent.parent)
      if(parent.parent){
        let pChildNodes = parent.parent.childNodes
        console.log('pChildNodes', pChildNodes)
        let preParentNode = {}
        pChildNodes.forEach((item, index) => {
          if(item.id == parent.id){
            if(index == 0){
              return ''
            }
            preParentNode = pChildNodes[index-1]
          }
        })
        if(!preParentNode.childNodes){
          return ''
        }
        let pLeng = preParentNode.childNodes.length
        console.log('pLeng', pLeng)
        if(pLeng == 0){
          return ''
        }
        let preNode = preParentNode.childNodes[pLeng-1]
        console.log('preNode', preNode)
        return preNode.data&&preNode.data.contentVersion&&preNode.data.contentVersion.content ? preNode.data.contentVersion.content : ''
      }else{
        return ''
      }
    },
    getStoryContentPre(){
      if(this.currentNode.parent){
        let childNodes = this.currentNode.parent.childNodes
        console.log('parent', this.currentNode.parent)
        // console.log('childNodes', childNodes, childNodes.length)
        if(childNodes.length < 2){
          return this.findPreParent(this.currentNode.parent);
        }
        // 同卷下
        let preNode = {}
        childNodes.forEach((item, index) => {
          if(item.id == this.currentNode.id){
            if(index == 0){
              return this.findPreParent(this.currentNode.parent);
            }else{
              preNode = childNodes[index-1]
              console.log('preNode1', preNode)
            }
          }
        })
        console.log('preNode', preNode)
        return preNode.data&&preNode.data.contentVersion&&preNode.data.contentVersion.content ? preNode.data.contentVersion.content : ''
      }
      return ''
    },
    startGenStoryContent(from) {

      if(from === 0){// 生成本章

      }else if(from === 1){// 重新生成 ”执行“

      }

      let params = Object.assign({}, this.story)
      params.articleId = this.story.id
      params.aiMasterFlag = this.aiMasterFlag
      params.chapterId = this.currentNode.data.id
      params.storyChapterOutline = this.storyChapterOutline
      params.storyOutlineRequired = this.storyChapterOutlineRequired
      // 上一章内容 第一章没有 Or
      params.storyContentPre = this.getStoryContentPre()
      params.platformCategory = 'WRITER_WEB'

      // valid
      console.log(`startGenStoryContent valid! `, this.story, params, params.storyContentPre)
      if (!this.storyChapterOutline) {
          this.$message.error('请输入故事大纲');
          this.focusOutline()
          return false;
      }
      // 更新大纲要求
      this.handleUpdateOutline()
// return false
      this.showSkeleton = true
      this.isLoading = true
      if(this.hideFlag){
        this.showZW()
      }
      let workflowId = "1819339645603966978"
      // console.log(this.storyChapterOutline)
      sse(workflowId, params, this.handleStoryContentMsg).then((result)=>{
        // console.log('result', result);
        // AI结束，处理逻辑
        setTimeout(()=>{
          this.isLoading = false
          // this.storyContent = this.replaceLineBeginBreaks(this.storyContent)
          // this.handlePageStoryContent()
          this.handlePageChapter(3)
          this.$refs.headeNvWriterRef.syncUser()
        }, 2000)
        ArticleApi.update({id: this.story.id, stepNumber: 0});
      }).catch(error => {
        // 处理错误
        console.error(error);
        // console.log('storyContents', this.storyContents);
        let usingFlagVersions = this.storyContents.filter(item => {
          return item.usingFlag
        });
        this.storyContentObj = usingFlagVersions.length > 0 ? usingFlagVersions[0] : {}
        this.storyContent = this.storyContentObj.content;
        this.isLoading = false;
        this.isSkeleton = false;
      });
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    replaceLineBeginBreaks(text) {
      // let text = "\n\nThis is a text with leading blank lines.\n";
      text = text.replace(/^\s*\n/gm, ""); // 删除开头的空行
      // console.log(text);
      return text;
    },
    scrollToBottom() {
      const msgbox = document.getElementById("msgbox")
      msgbox.scrollTop = msgbox.scrollHeight;
    },
    focusTitle() {
      let input = this.$refs.customtitle
      input.focus();
    },
    focusOutline() {
      let input = this.$refs.outlinebox
      input.focus();
    },
    handlePageChapter(flag){
      let articleId = this.story.id
      if(!articleId) {
        return false;
      }
      if(flag == 0){ //初始化

      }else if(flag == 1){ //drop

      }else if(flag == 2){ // version change

      }else if(flag == 3){ // AI 生文之后

      }
      ChapterApi.page({articleId: this.story.id, size: 1000}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.total > 0){
            this.chapters = result.records;
            this.treeData = this.chapters.filter(item => {
              return item.parentId == 0
            })

            this.treeData.forEach((item, index) => {
              if (!item.children) {
                this.$set(item, 'children', []);
              }
              this.$set(item, 'children', this.chapters.filter(itemIn => {
                return itemIn.parentId == item.id
              }));
            })

            this.$nextTick(()=>{
              // init current node
              if(flag == 0 || flag == 1){
                let lastParentNode = this.treeData[this.treeData.length - 1]
                console.log('lastParentNode', lastParentNode);
                let nodekey = lastParentNode.id
                if(lastParentNode.children.length > 0){
                  let lastNode = lastParentNode.children[lastParentNode.children.length - 1]
                  nodekey = lastNode.id
                }
                console.log('nodekey', nodekey);
                let node = this.$refs.tree.getNode(nodekey)
                this.currentNode = node
                this.currentParentNode = node.parent
                this.currentVolume = node.level == 1 ? node : node.parent
                this.storyChapterOutline = this.currentNode.data.content
                this.storyChapterOutlineRequired = this.currentNode.data.contentRequired
                console.log('currentNode', this.currentNode);
                this.$refs.tree.setCurrentKey(this.currentNode.data.id)
                console.log('treeData', this.treeData)

              }else if(flag == 2){// 改变版本
                this.$refs.tree.setCurrentKey(this.currentNode.data.id)
              }else if(flag == 3){// AI 生文之后
                console.log('flag=3', this.currentNode);
                this.currentNode = this.$refs.tree.getNode(this.currentNode.data.id)
                console.log('flag=3 1', this.currentNode);

                this.currentVolume = this.currentNode.level == 1 ? this.currentNode : this.currentNode.parent
                this.currentParentNode = this.currentNode.parent
                this.storyChapterOutline = this.currentNode.data.content
                this.storyChapterOutlineRequired = this.currentNode.data.contentRequired
                console.log('currentNode', this.currentNode);
                this.$refs.tree.setCurrentKey(this.currentNode.data.id)
              }
              this.handlePageStoryContent();
            })
          }else{
            console.log('无章节数据')
          }
          this.isLoading = false
          this.showSkeleton = false
        }
      })
    },
    handleUpdateTitle() {
      if(this.story.title){
        ArticleApi.update({id: this.story.id, title: this.story.title}).then(res => {

        });
      }
    },
    handleCreateChapter(flag) {
      if(!this.story.id){
        this.$message.error('故事ID丢失，请重新进入。');
        return false;
      }
      let params = {}
      params.articleId = this.story.id
      console.log('flag='+ flag, this.currentNode, this.currentVolume)
      if(flag == 0){
        // 章
        // 当前章
        let childNodes = this.currentVolume.childNodes || []
        let lastChild = childNodes.length > 0 ? childNodes[childNodes.length-1] : null
        let chapterNumber = lastChild ? this.extractFirstNumber(lastChild["data"]["title"]) + 1 : 1
        console.log(childNodes, lastChild, chapterNumber)
        let data = {}
        if(this.currentNode.level == 2){
          params.parentId = this.currentParentNode.data.id
          // chapterNumber = this.currentParentNode.childNodes.length
          data = this.currentParentNode.data;
        } else {
          params.parentId = this.currentNode.data.id
          // chapterNumber = this.currentNode.childNodes.length
          data = this.currentNode.data;
        }
        if(!params.parentId){
          this.$message.error('请选择章节');
          return false;
        }
        console.log('create chapter data', data);

        params.chapterNumber = chapterNumber
        params.title = '第'+params.chapterNumber+'章'
        ChapterApi.create(params).then(res => {
          console.log('create chapter', res);
          const newChild = res.data.data;
          if (!data.children) {
            this.$set(data, 'children', []);
          }
          data.children.push(newChild);
        });

      }else if(flag == 1){
        console.log('第几卷',this.currentNode, this.currentParentNode);
        // 卷
        // 追加到最后，名字：第几卷
        params.parentId = 0
        let chapterNumber = 0
        let data = {}
        if(this.currentNode.level == 2){
          chapterNumber = this.currentParentNode.parent.data.length
          data = this.currentParentNode.parent.data;
        } else {
          chapterNumber = this.currentNode.parent.childNodes.length
          data = this.currentNode.parent.data;
        }
        params.chapterNumber = chapterNumber+1
        params.title = '第'+this.convertToChineseNum(chapterNumber+1)+'卷'
        ChapterApi.create(params).then(res => {
          console.log('create chapter', res);
          const newChild = res.data.data;
          if (!data.children) {
            this.$set(data, 'children', []);
          }
          data.push(newChild);
        });
      }
    },
    handleRename(node) {
      console.log('handleRename', node);
      this.editFlag = true;
      this.nameInputValue = node.data.title
      console.log('this.editFlag', this.editFlag, this.nameInputValue);

    },
    handleRenameOk(node) {
      console.log('handleRenameOk', node);
      if(!this.nameInputValue || !this.nameInputValue.trim()){
        return false;
      }
      ChapterApi.update({id:node.data.id, title: this.nameInputValue}).then(res => {
        node.data.title = this.nameInputValue
        this.editFlag = false;
      });
    },
    handleRemove(node) {
      this.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ChapterApi.remove(node.data.id).then(res => {
          this.handlePageChapter(1)
        });
      }).catch((e) => {console.log('error', e)});
    },
    handleNodeClick(item, node, tree) {

      this.currentNode = node
      this.currentParentNode = node.parent;
      this.currentVolume = node.level == 1 ? node : node.parent
      this.currentChapter = node.level == 2 ? node : {}
      console.log('nodeClick', item, node, node.parent, tree);
      // if(node.level === 1 ) return false;
      // 大纲 更新
      this.storyChapterOutline = node.data.content
      this.storyChapterOutlineRequired = node.data.contentRequired
      if(this.hideFlag){
        this.$nextTick(()=>{
          this.showZW()
        })
      }
      this.handlePageStoryContent()

    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
    },
    handleEnum() {
      EnumApi.list().then(res => {
        this.enums = res.data.data;
      })
    },
    downloadFile2() {
      const filename = this.story.title + '-' + this.currentParentNode.data.title + '-' + this.currentNode.data.title
      const text = this.storyContent
      var FileSaver = require('file-saver');
      var blob = new Blob([text], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, filename);
    },
    handlePageStoryContent(){
      StoryContentApi.page({fromType: 'gencp', storyId: this.story.id, chapterId: this.currentNode.data.id, size: 1000}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(Number(result.total) > 0){
            // console.log('result.records[0].storyContentVersions', result.records[0].storyContentVersions);
            let versions = result.records[0].storyContentVersions
            this.setStoryContents(versions)
            // console.log('storyContents', this.storyContents);
            let usingFlagVersions = versions.filter(item => {
              return item.usingFlag
            });
            this.storyContentObj = usingFlagVersions.length > 0 ? usingFlagVersions[0] : {}
            this.storyContent = this.storyContentObj.content;
            this.isLoading = false
            this.showSkeleton = false
          }else{
            this.setStoryContents([])
            this.storyContentObj = {};
            this.storyContent = '';
          }
          this.initMobileStyle()
        }
      })
    },
    nodeHover(node){
      console.log(node)
    },
    extractFirstNumber(inputString) {
    // 使用正则表达式匹配第一个数字
    const match = inputString.match(/\d+/);

    // 如果匹配到数字，则返回该数字，否则返回 null
    return match ? Number(match[0]) : 0;
}
  },
  beforeDestroy() {
    this.setShowChangpianDialogFlag(false)
    this.setStory({id: ''})
  }
}
</script>

<style scoped lang="scss">
$lightBgColor: #E5E7FC;
$lightBgColor2: #CDD1FF;
$mainTextColor: #0052D9;
.writer-box {
  &-content {
    background: #FAFAFD;
    padding: 1rem;
    &-work {
        height: calc( 100% - 3rem ) !important;
      &-left {
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.05);
        border-radius: 20px;
        width: 100%;
        display: flex;
        padding: 1rem;
        margin-right: 1rem;
      }
      &-outline {
        height: 100%;
        // width: 15rem;
        overflow-y: hidden; /* 启用垂直滚动条 */
        overflow-x: hidden; /* 隐藏水平滚动条 */
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: $mainTextColor;
          padding: .5rem 0;
          &-disabled {
            color: rgba(36,36,36,0.3);
          }
        }
        &-time {
          font-weight: 400;
          font-size: .8rem;
          color: rgba(0,0,0,0.6);
          padding: .5rem 0;
          &-disabled {
            color: rgba(36,36,36,0.3);
          }
        }
      }
      &-editor {
        width: calc( 100% - 15rem );
        height: calc( 100% - 4rem );
        border-radius: 20px ;
      }
      &-current-outline {
        // width: 20rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        &-top {
          background: #F2F3FF;
          border-radius: 10px;
          height: 100%;
          margin-bottom: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
    &-title {
    }
  }
}
/deep/ .el-dropdown-menu.el-popper {
  left: 2rem;
}
/deep/ .writer-box-content {
    max-width: 1400px !important;
    height: calc(100% - 6rem);
}
.outline-list-box {
  height: calc( 100% - 16rem);
  overflow-y: scroll;
}
.outline-list-box div:first-child {
  margin-top: 0 !important;
}
.story-content {
  min-height: 20rem;
}
.outline-active {
  background: $lightBgColor;
  color: $mainTextColor;
}
.outline-item:hover {
  background: $lightBgColor;
  color: $mainTextColor;
}
/deep/ .el-textarea__inner {
  resize: none; /* 禁止调整大小 */
}
/deep/ .writer-box-content-work-outline .el-card {
  margin-right: 1rem;
  overflow-y: hidden !important;
  border-radius: 10px;
}
/deep/ .writer-box-content-work-outline .el-card .el-card__body {
  padding: 0 .5rem;
}

/* 去除 el-select 组件的边框 */
/deep/ .custom-select .el-input__inner {
    border: none; /* 去除边框 */
    outline: none; /* 去除聚焦时的轮廓 */
    box-shadow: none; /* 去除阴影 */
    background: #E5E7FC;
    border-radius: 6px;
    color: $mainTextColor;
    // height: 22px;
    // line-height: 22px;
}
/deep/ .current-select .el-input__inner {
    background: #E5E7FC;
}
/deep/ .current-select .el-input__inner::placeholder {
    color: #0052D9; /* 设置你想要的颜色 */
}
/deep/ .current-select.el-select .el-input .el-select__caret {
  color: #0052D9;
}

/* 如果你还想去除悬浮状态的边框 */
/deep/ .custom-select .el-input__inner:hover {
    border-color: transparent; /* 边框颜色设置为透明 */
    background: #F2F3FF;
}
/deep/ .custom-form .el-dialog__body {
  padding: 0 3rem 2rem 3rem;
}
/deep/ .custom-title .el-input__inner {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    padding-left: 0.5rem;
    font-size: 1.2rem;
    color: #000000;
}

/deep/ .no-border-input .el-textarea__inner {
    border: none;
    outline: none;
    box-shadow: none;
}
/deep/ .no-border-input .el-textarea__inner:hover,
/deep/ .no-border-input .el-textarea__inner:focus {
    border-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
}
/deep/ .el-dialog {
    border-radius: 20px; /* 调整圆角大小 */
}
/deep/ .custom-textarea .el-textarea__inner {
  // background: #FAFAFD;
  border-radius: 20px;
  border: 1px solid rgba(0,82,217,0.26);
  font-weight: 400;
  font-size: 14px;
  color: rgba(0,0,0,0.9);
  border-radius: 10px;
  background-color:  #EBF0FE;
  padding-top: 1.7rem;
}
/deep/ .msgbox .el-textarea__inner {
  border-radius: 20px;
  border: 1px solid rgba(0,82,217,0.26);
  font-size: .9rem;
}
/deep/ .outlinebox .el-textarea__inner {
  border-radius: 10px;
  font-size: .9rem;
  border: none;
  outline: none;

}
/* CSS样式 */
.image-switcher {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url('../../assets/writer-bianji.png'); /* 默认状态下的图片 */
  background-size: cover;
  transition: background-image 0.3s; /* 平滑过渡效果 */
}

/* 鼠标悬浮时显示的图片 */
.image-switcher:hover {
  background-image: url('../../assets/writer-bianji-2.png'); /* 默认状态下的图片 */
}

/* 鼠标点击时显示的图片，注意 :active 选择器可能需要与 :hover 结合使用 */
.image-switcher:active {
  background-image: url('../../assets/writer-bianji-3.png'); /* 默认状态下的图片 */
}

.section-box {
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-flow: nowrap row;
  padding: 0.5rem 1rem;
  background: $lightBgColor;
  border-radius: 20px;
  margin-bottom: 1rem;

  .button {
    display: inline-block;
    background: #366EF4;
    font-size: 12px;
    border-radius: 50px;
    border: 0;
    padding: 0 15px;
    height: 28px;
    line-height: 28px;
    color: #fff;
    margin-left: 10px;
    font-weight: normal;

    .icon {
      transform: rotate(90deg);
      margin-right: 5px;
    }

    &.is-disabled {
      color:#C0C4CC;
      background: #FFFFFF;
    }
  }
}

/deep/ .el-icon-files:before, .el-icon-document:before  {
    margin-right: .25rem;
}
.custom-button.default:hover {
    background-color: #F2F3FF;
    // background-color: #000000;
    // color: white;
}
.custtom-button1.default:hover {
    background-color: #F2F3FF;
}

.mly-switch {
  /deep/ .el-switch__label.is-active{
    color: rgba(0,0,0,0.5);
    white-space: nowrap;
  }
  /deep/ .el-switch__label {
    white-space: nowrap;
    color: rgba(0,0,0,0.5);
    span {
      font-size: 12px;
    }
  }
  /deep/ .el-switch__core {
    width: 30px !important;
    height: 14px !important;
    border-radius: 10px !important;
  }
  /deep/ .el-switch__core:after {
    top: 0 !important;
    left: 17px !important;
    width: 12px !important;
    height: 12px !important;
    // margin-left: -16px;
  }
  /deep/ .el-switch.is-checked .el-switch__core {
    width: 30px !important;
    height: 14px !important;
    left: 0 !important;
    border-radius: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .section-box {
    display: flex;
  }
  .writer-box-content-work {

  }
}
</style>
