<template>
<!-- contact -->
<div class="">
    <div class="eye-container">
        <div class="blinking-eye pointer" @click="showContent"></div>
    </div>
    <AIHelper v-if="showFlag"></AIHelper>
</div>
</template>
<script>
import * as AccountApi from '@/api/account'
import { mapState, mapMutations} from 'vuex'
import AIHelper from '@/components/AIHelper'
import VueDragResize from 'vue-drag-resize';
export default {
  name: 'Contact',
  components: {
    VueDragResize,AIHelper
  },
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      cozeWebSDK: null,
      showFlag: false
    }
  },
  computed: {
      ...mapState(['user', 'token']),
  },
  mounted() {
    this.initChatBot()
  },
  methods: {
    ...mapMutations(['setShowLoginDialogFlag']),
    destroyBot(){
      if(this.cozeWebSDK){
        this.cozeWebSDK.destroy();
      }
    },
    async initChatBot(){
      if(this.cozeWebSDK){
        return false;
      }
      if(!this.user.id || this.user.id == ''){
        return false;
      }
      let res = await AccountApi.update({id:this.user.id, fromType: "writer-client-coze-bot-token"});
      let result = res.data.data;
      let token = result.cozeChatBotToken
      this.cozeWebSDK = new CozeWebSDK.WebChatClient({
        config: {
          bot_id: '7485197994254843919'
        },
        componentProps: {
          title: '码哩写作AI总编',
        },
        auth: {
          type: 'token',
          token: token,
          onRefreshToken: async () => {
            let res = await AccountApi.update({id:this.user.id, fromType: "writer-client-coze-bot-token"});
            let result = res.data.data;
            return result.cozeChatBotToken
          }
        },
        userInfo: {
          id: this.user.id,
          url: 'https://maliwriter.com/static/ai-user.jpg',
          nickname: '我',
        },
        ui: {
          base: {
            icon: 'https://maliwriter.com/static/ai-author-open.png'
          },
          asstBtn: {
            isNeed: false,
          },
          footer: {
             isShow: true,
             expressionText: '以上内容由 AI 生成，仅供参考',
          }
        }
      });
    },
    showContent(){
      this.initChatBot()
      this.showFlag = true
    }
  }
}
</script>

<style scoped lang="scss">

.eye-container {
    position: fixed;
    right: -26px;
    top: 50%;
    transform: rotate(-45deg);
    width: 60px;
    height: 60px;
    transition: right 0.3s ease;
    z-index: 8000;
}

.eye-container:hover {
    right: 0px;
    transform: rotate(0deg);
}

</style>
