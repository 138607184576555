<template>
<div style="display: flex;justify-content: space-between;align-items: center;
    margin-bottom: -2rem;z-index: 10;
    background: #ebf0fe;
    border-radius: 10px 10px 0 0;
    border: 1px solid rgba(0, 82, 217, 0.26);
    border-bottom: none;
    padding: 10px 1rem;position: relative;">
  <div class="" style="font-weight: 600; color: rgba(54,110,244,0.5);">
    写作指令：
  </div>
  <div class="">
    <div class="mly-custom-button" @click.stop="handleOpen">
      <div class="icon"></div>
      <span>快捷指令</span>
    </div>
  </div>
  <!-- <div @click="init">parent</div> -->
</div>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'ZhilingOpen',
  props: {
    fromChannel: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  data() {
    return {

    }
  },
  beforeDestroy() {

  },
  mounted() {

  },
  computed: {
  	...mapState(['showZhilingPanelFlag', 'enums']),
  },
  methods: {
    ...mapMutations(['setShowZhilingPanelFlag']),
    init(){
      console.log(this.$parent.$parent);
    },
    handleOpen(){
      console.log('parent', this.$parent, this.$parent.$parent);
      if(this.fromChannel == 'changpian'){
        // 没有 dialog, 父元素与其他处理方式不一样
        this.$parent.$refs.myZhilingPanel.handleOpen();
      }else{
        this.$parent.$parent.$parent.$refs.myZhilingPanel.handleOpen();
        // this.$parent.$parent.$parent.$refs.myZhilingPanel.style.zIndex = 9999; // 动态设置 z-index
        // console.log('style', this.$parent.$parent.$parent.$refs.myZhilingPanel.style);
        // alert(this.$parent.$parent.$parent.$refs.myZhilingPanel.style.zIndex)
      }
    }
  }
}
</script>

<style type="text/css">
.mly-custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2878FF;
  background-color:  #d4e4ff;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  padding: .25rem .5rem;
  width: 6rem;
  transition: transform 0.3s ease;
}

.mly-custom-button .icon {
  width: 16px;
  height: 16px;
  background-image: url('../../assets/icon-zhiling-open-s.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.3s ease;
}

.mly-custom-button:hover {
  color: #d4e4ff;
  background-color:  #2878FF;
}

.mly-custom-button:hover .icon {
  /* transform: rotate(360deg); */
  background-image: url('../../assets/icon-zhiling-open.png');
}

.mly-custom-button:hover span {
  color: #d4e4ff;
}
</style>
<style scoped lang="scss">

</style>
