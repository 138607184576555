<template>
  <div>
    <div class="eye-container">
      <div class="close-btn" @click="closeWindow">×</div>
      <div class="content">
        <div class="d-flex flex-center-y" style="">
          <div class="icon">
            <div class="blinking-eye ai-bot"></div>
          </div>
          <div style="color: #ffffff;font-size: 14px;padding-left: 6px;">检测到您可能遇到以下问题：</div>
        </div>

        <div class="item-list">
          <div class="item" v-for="(item,index) in tableData" :key="index" @click="goPage(item)">{{item.courseName}}</div>
        </div>
        <div class="more pointer" v-if="hasMore" @click="goMore">更多>></div>
        <button v-if="token != ''" class="ask-btn pointer" @click="closeWindow();$parent.cozeWebSDK.showChatBot()">
          <span class="gradient-text">问问AI总编</span>
        </button>
        <button v-else class="ask-btn pointer" @click="closeWindow();setShowLoginDialogFlag(true)">
          <span class="gradient-text">问问AI总编</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations} from 'vuex'
import * as CertificateApi from '@/api/certificate'
export default {
  name: 'FloatWindow',
  data() {
    return {
      hasMore: false,
      hasMoreLinkUrl: '',
      queryForm: {
        no: '',
        current: 1,
        size: 5
      },
      pageUris: [
        {name: '/workspace/genbyidea', desc: '超短篇'},
        {name: '/workspace/genbyoutline', desc: '短篇定制'},
        {name: '/workspace/gencp', desc: '长篇'},
        {name: '/workspace/gen', desc: '短篇'},
        {name: '/bookbreakdown/index', desc: '拆书'},
        {name: '/workspace/chaishu', desc: '拆书'},
        {name: 'other', desc: '其它'},
      ],
      tableData: []
    };
  },
  computed: {
      ...mapState(['user', 'token']),
  },
  mounted() {
    this.handlePage()
    this.initHasMore()
  },
  beforeDestroy() {
    // this.destroyBot();
    console.log('beforeDestroy')
  },
  methods: {
    ...mapMutations(['setShowLoginDialogFlag']),
    closeWindow() {
      this.$parent.showFlag = false;
    },
    getCurrentUri() {
      const uri = window.location.pathname//this.$route.fullPath; // 获取路由完整路径，例如 "/path?query=123"
      console.log('当前 URI:', uri);
      return uri;
    },
    startWithByName(name) {
      if(!name || name == ''){
        return 'other'
      }
      const element = this.pageUris.find(item => name.startsWith(item.name));
      if (element) {
        if(element.desc == '拆书'){
          return '/workspace/chaishu'
        }
        return element.name
      } else {
        return 'other'
      }
    },
    initHasMore() {
      CertificateApi.create({fromType: "writer-helper-more-view"}).then(res => {
        let result = res.data.data;
        let uri = this.getCurrentUri()
        if(uri.startsWith('/workspace/genbyidea')){
          this.hasMore = result.genbyideaHasMore
          this.hasMoreLinkUrl = result.genbyideaHasMoreLinkUrl
        }else if(uri.startsWith('/workspace/genbyoutline')){
          this.hasMore = result.genbyoutlineHasMore
          this.hasMoreLinkUrl = result.genbyoutlineHasMoreLinkUrl
        }else if(uri.startsWith('/workspace/gencp')){
          this.hasMore = result.gencpHasMore
          this.hasMoreLinkUrl = result.gencpHasMoreLinkUrl
        }else if(uri.startsWith('/workspace/gen')){
          this.hasMore = result.genHasMore
          this.hasMoreLinkUrl = result.genHasMoreLinkUrl
        }else if(uri.startsWith('/bookbreakdown/index')||uri.startsWith('/workspace/chaishu')){
          this.hasMore = result.chaishuHasMore
          this.hasMoreLinkUrl = result.chaishuHasMoreLinkUrl
        }else{
          this.hasMore = result.otherHasMore
          this.hasMoreLinkUrl = result.otherHasMoreLinkUrl
        }
      })
    },
    handlePage() {
      let uri = this.getCurrentUri()
      this.queryForm.no = this.startWithByName(uri)
      CertificateApi.page(this.queryForm).then(res => {
        let result = res.data.data;
        this.tableData = result.records
      })
    },
    goPage(item){
      if(item.picUrl && item.picUrl != ''){
        window.open(item.picUrl, '_blank')
      }
    },
    goMore(){
      if(this.hasMoreLinkUrl && this.hasMoreLinkUrl != ''){
        window.open(this.hasMoreLinkUrl, '_blank')
      }
    }
  }
};
</script>

<style scoped>
.eye-container {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 270px;
  background: linear-gradient( 171deg, #BEC2FE 0%, #B1C6FB 100%);
  box-shadow: inset 2px 4px 10px 0px rgba(255,255,255,0.6);
  border-radius: 20px 0px 0px 20px;
  padding: 30px 20px 20px 20px;
  z-index: 8001;
  transition: all 0.3s ease;
}
.eye-container:hover .icon {
    animation: bounce 0.8s;
    animation-timing-function: cubic-bezier(0.5, 0.05, 0.5);
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-6px);
    }
/*    60% {
        transform: translateY(-2px);
    } */
    100% {
        transform: translateY(0);
    }
}
.close-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 13px;
  color: #fff;
  background: rgba(0,0,0,0.2);
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: 100%;
}

.icon {
  width: 40px;
  height: 40px;
  transition: all 0.3s;
}

span {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
}

.item-list {
  margin-bottom: 16px;
}

.item {
  cursor: pointer;
  margin: 8px 0;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF;
  background: rgba(0, 82, 217, .6);
  box-shadow: inset 4px 4px 10px 0px rgba(255,255,255,0.4), 0px 4px 4px 0px rgba(46,89,158,0.41);
  border-radius: 12px 12px 12px 12px;
}

.more {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
  padding-left: 12px;
}

.ask-btn {
  width: 100%;
  padding: 10px;
  background: rgba(255,255,255,0.4);
  box-shadow: inset 4px 4px 10px 0px #FFFFFF;
  border-radius: 50px 50px 50px 50px;
  font-weight: bold;
  cursor: pointer;
  border: 0;
}
.gradient-text {
  background: linear-gradient(90deg, #00FFC2 0%, #557CF6 47%, #9B9EFC 100%);
  -webkit-background-clip: text; /* 将背景剪切到文字 */
  background-clip: text; /* 标准属性，兼容性稍差 */
  color: transparent; /* 文字本身透明，显示背景渐变 */
  display: inline-block; /* 确保剪切效果正常 */
}
</style>
