<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="writer-box-content">

      <!-- steps -->
      <StepsWriter :active="3"/>

      <!-- elements -->
      <div class="hidden-xs-only" style="font-weight: 600; font-size: 20px; color: #000000; line-height: 24px;margin: 1rem 0;">
        故事章纲
      </div>
      <div class="writer-box-content-editor">
        <el-input class="global-box-scrollbar"
         @input="changeInput"
         v-if="!isSkeleton" :disabled="!storyOutlineFull" id="msgbox" type="textarea" v-model="storyOutlineFull"></el-input>
        <LlmSkeleton v-else />
      </div>
    </div>

    <!-- footer -->
    <div class="writer-box-footer" :class="{'button-flex-between': story.articleGenType=='GEN_BY_ZHIHU'}">
      <span v-show="story.articleGenType=='GEN_BY_ZHIHU' && storyOutlineFulls.length==0"></span>
      <span v-show="story.articleGenType=='GEN_BY_ZHIHU' && storyOutlineFulls.length>0">
        <!-- <el-checkbox style="margin-right: .5rem;" v-model="checked4ElementVersion" @change="changeElementVersion"></el-checkbox> -->
        <el-select class="custom-select"
            v-model="story.storyOutlineFullId"
            filterable
            size="mini"
            :disabled="isLoading"
            placeholder="版本">
          <el-option
            v-for="(itemVersion, index) in storyOutlineFulls"
            @click.native.stop="contentChange(itemVersion)"
            :key="itemVersion.id"
            :label="'版本 ' + itemVersion.version"
            :value="itemVersion.id"
          />
        </el-select>
      </span>
      <span class="button-group">
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" round @click="genStoryOutlineFull()" :loading="isLoading" :disabled="isLoading" v-if="story.articleGenType=='GEN_BY_ZHIHU' && storyOutlineFulls.length==0">生成章纲</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="default" round icon="el-icon-refresh" @click="handleSave(1)" :loading="isLoading" :disabled="isLoading" v-if="story.articleGenType=='GEN_BY_ZHIHU' && storyOutlineFulls.length>0">重新生成</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="handleSave(2)" :disabled="isLoading" v-if="story.articleGenType=='GEN_BY_ZHIHU' && storyOutlineFulls.length>0">下一步</el-button>
      </span>
    </div>

  </div>
</template>
<script>
import StepsWriter from '@/components/StepsWriterZhihu'
import HeadNavWriter from '@/components/HeadNavWriter'
import LlmSkeleton from '@/components/LlmSkeleton'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as StoryOutlineApi from '@/api/storyoutline'
import * as StoryOutlineFullApi from '@/api/storyoutlinefull'
import * as StoryTemplateQuestionApi from '@/api/storytemplatequestion'
import sse from '@/utils/llmstream'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    LlmSkeleton, HeadNavWriter, StepsWriter
  },
  data() {
    return {
      checked4ElementVersion: false,
      fromType: 'novel',
      enums: [],
      isRequesting: false,
      isLoading: false,
      isSkeleton: false,
    	autoRunedFlag: false,
      tableHeight: 100,
      // storyOutline: null,
      storyOutlinesPrue: [],
      storyOutlineFull: null,
      // storyOutlineFulls: [{id: '0', content: '', version: 1}],
      storyOutlineFulls: [],
      storyOutlineTemplate: `01
xxx

02
xxx

03
xxx

04
xxx

05
xxx

`
    }
  },
  computed: {
  	...mapState(['user', 'story', 'dialogShowCostomContactFlag'])
  },
  mounted() {
    this.$nextTick(() => {

    })
    this.handlePageStoryOutlineFull()
    this.handleEnum()
  },
  methods: {
    ...mapMutations(['setStory', 'setDialogShowCostomContactFlag']),
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        customClass: 'custom-loading',
        text: '正在保存...',
        // spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const requestingInterval = setInterval(() => {
        console.log('this.isRequesting', this.isRequesting);
        if(this.isRequesting){

        }else{
          loading.close();
          if(requestingInterval){
            clearInterval(requestingInterval)
          }
          this.goNextPage()
        }
      }, 2000);
    },
    goNextPage(){
      this.$router.push('/workspace/gen4')
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    contentChange(item){
      // console.log('contentChange', item.content);
      this.storyOutlineFull = item.content
      this.story.storyOutline = item.content
      this.story.storyOutlineFullId = item.id
      this.setStory(this.story)
      this.handleUpdateStory(item)
    },
    changeInput(event){
      // console.log('changeInput', this.story.storyOutlineFullId, this.story.storyOutlineFull);
      this.handleUpdateStory({id:this.story.storyOutlineFullId, content:this.storyOutlineFull})
      // 更新 outlinefull
      this.handleUpdateOutlineFullSave()
    },
    handleUpdateStory(item) {
      ArticleApi.update({id: this.story.id,
          storyOutlineFullId: item.id,
          storyOutline: item.content}
      ).then(res => {

      });
    },
    handleUpdateOutlineFullSave() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      if(!this.story.storyOutlineFullId || this.story.storyOutlineFullId == 0){
        return false
      }

      this.storyOutlinesPrue = this.$parseContent(this.storyOutlineFull)
      if(this.storyOutlinesPrue.length==0){
        return false
      }

      StoryOutlineFullApi.update({id: this.story.storyOutlineFullId,
          content: this.storyOutlineFull}
      ).then(res => {
          this.story.storyOutline = this.storyOutlineFull
          this.setStory(this.story)
          // 更新this.storyElements = [];
          this.storyOutlineFulls.forEach((item,index)=>{
            if(this.story.storyOutlineFullId == item.id){
              item.content = this.storyOutlineFull
            }
          })
      });
    },
    handleUpdateOutlineFull() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      console.log('handleSave storyOutlineFull', this.storyOutlineFull, this.storyOutlineTemplate, this.storyOutlineFull == this.storyOutlineTemplate);
      if (!this.storyOutlineFull || !this.storyOutlineFull.trim()
          || this.storyOutlineFull == this.storyOutlineTemplate) {
          // this.$message.error('请输入故事大纲');
          console.log('大纲丢失');
          return false;
      }
      this.isRequesting = true;
      // 用户修改大纲，自动保存（生成过正文；未生成过
      // console.log('storyOutlinesPrue update -------------- ' + this.storyOutlinesPrue);
      StoryOutlineFullApi.create({storyId: this.story.id,
        content: this.storyOutlineFull, storyOutlineFullId: this.story.storyOutlineFullId, fromType: 'outline_auto_save',
        storyOutlines: this.storyOutlinesPrue}).then(res => {
        this.story.storyOutlineFull = this.storyOutlineFull
        // this.storyOutlineFulls[result.records.length-1].content = this.storyOutlineFull
        // 更新this.storyElements = [];
        this.handleUpdateStoryOutlineFullVersions()
        if(this.story.stepNumber<4){
          this.story.stepNumber = 4
        }
        this.setStory(this.story)
        this.isRequesting = false;
      })
    },
    handleUpdateStoryOutlineFullVersions(){//内容变化 更新版本
      StoryOutlineFullApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        this.storyOutlineFulls = result.records;
      })
    },
    handlePageStoryOutlineFull(flag){
      StoryOutlineFullApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.records.length > 0){
            this.storyOutlineFulls = result.records;
            let currentOutlineFull = this.storyOutlineFulls.filter(i=>{return i.id==this.story.storyOutlineFullId})[0];
            if(currentOutlineFull){
              this.storyOutlineFull = currentOutlineFull.content
              this.story.storyOutlineFullId = currentOutlineFull.id
            }else{
              this.story.storyOutlineFullId = this.storyOutlineFulls[result.records.length-1].id
              this.storyOutlineFull = this.storyOutlineFulls[result.records.length-1].content
            }
          }else{
            this.storyOutlineFulls = [];
          }

          this.story.storyOutlineFull = this.storyOutlineFull
          this.setStory(this.story)

          if ((!this.storyOutlineFull || !this.storyOutlineFull.trim()) && this.story.articleGenType == 'GEN_BY_OUTLINE') {
            this.storyOutlineFull = this.storyOutlineTemplate
          }
          console.log('handlePageStoryOutlineFull', this.story, result.records);
          if(result.records.length == 0){// 从创意开始，自动生成要素
            if(this.user.desktopFlag && !this.autoRunedFlag){
              this.autoRunedFlag = true
              this.genStoryOutlineFull()
            }
          }
          if(flag && flag == 3 && result.records.length>0){
            this.contentChange(this.storyOutlineFulls[this.storyOutlineFulls.length-1])
          }
        }
      })
    },
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.enums = result
      })
    },
    handleSave(flag) {
      // console.log('handleSave storyOutlineFull', this.storyOutlineFull, this.storyOutlineTemplate, this.storyOutlineFull == this.storyOutlineTemplate);
      if (!this.storyOutlineFull || !this.storyOutlineFull.trim()
            || this.storyOutlineFull == this.storyOutlineTemplate) {
          this.$message.error('请输入故事章纲');
          return false;
      }
      if(flag == 0){//by outline 下一步 只有一个版本
        // 避免重复

      }else if(flag == 1){// by idea : 重新生成
        this.genStoryOutlineFull((msg)=>{
          // console.log(msg)
          this.isLoading = false;
        })
      }else if(flag == 2){// by idea : 下一步 (以当前选择的版本生文)

        this.storyOutlinesPrue = this.$parseContent(this.storyOutlineFull)
        if(this.storyOutlinesPrue.length==0){
          return false
        }

        if(this.story.stepNumber<4){
          this.story.stepNumber = 4
          this.setStory(this.story)
        }
        this.handleUpdateOutlineFull();
        if(this.isRequesting) {
          this.openFullScreen()
        }else{
          this.goNextPage();
        }
      }else if(flag == 3){//by idea AI生成完之后保存版本

        StoryOutlineFullApi.create({storyId: this.story.id, content: this.storyOutlineFull,
         storyOutlines: this.storyOutlinesPrue}).then(res => {
          this.story.storyOutlineFull = this.storyOutlineFull
          console.log('gen3 flag 3')
          //更新章节数
          if(this.story.stepNumber<4){
            this.story.stepNumber = 4
            this.setStory(this.story)
          }
          this.handlePageStoryOutlineFull(3)
        })
      }
    },
    scrollToBottom() {
      const msgbox = document.getElementById("msgbox")
      msgbox.scrollTop = msgbox.scrollHeight;
    },
    handleStoryOutlineFullMsg(msg){
      // console.log('element', msg);
      this.isSkeleton = false
      this.storyOutlineFull = msg
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    genStoryOutlineFull() {
        console.log('story::element', this.story);
        this.isLoading = true;
        this.isSkeleton = true
        this.storyOutlineFull = ''
        let params = Object.assign({}, this.story)
        params.articleId = this.story.id
        params.platformCategory = 'WRITER_WEB'
        // new 自由模式
        let workflowId = "1828720201341005825"
        // 模板模式
        if(this.story.storyTemplateId && this.story.storyTemplateId != '0'){
          workflowId = "1828805333150265345";
          // 获取当前用户的QA列表（细纲大纲)
          let templateId = this.story.storyTemplateId
          StoryTemplateQuestionApi.page({templateId, storyId:this.story.id, accountId:this.user.id,size: 20}).then(res => {
            let result = res.data.data;
            let storyTemplateQuestions = result.records

            let history = []
            storyTemplateQuestions.forEach((item,index) => {
              if(item.answerVo && item.answerVo.answer){
                history.push('Q'+(index+1)+':'+item.title)
                history.push('A'+(index+1)+':'+item.answerVo.answer)
              }else{
                history.push('Q'+(index+1)+':'+item.title)
                history.push('A'+(index+1)+':')
              }
            })
            console.log('findQaHistory ', history);
            params.storyQaHistory = history.join('\n')

            sse(workflowId, params, this.handleStoryOutlineFullMsg).then((result)=>{
              // console.log('result', result);
              //AI结束，处理逻辑
              this.isLoading = false;
              this.isSkeleton = false;
              this.storyOutlineFull = result
              this.story.storyOutline = result
              this.setStory(this.story)
              // 保存版本
              this.handleSave(3)
              this.$refs.headeNvWriterRef.syncUser()
            }).catch(error => {
              // 处理错误
              console.error(error);
              let currentOutlineFull = this.storyOutlineFulls.filter(i=>{return i.id==this.story.storyOutlineFullId})[0];
              if(currentOutlineFull){
                this.storyOutlineFull = currentOutlineFull.content
              }
              this.isLoading = false;
              this.isSkeleton = false;
            });
          });

        }else{
          sse(workflowId, params, this.handleStoryOutlineFullMsg).then((result)=>{
            // console.log('result', result);
            //AI结束，处理逻辑
            this.isLoading = false;
            this.isSkeleton = false;
            this.storyOutlineFull = result
            this.story.storyOutline = result
            this.setStory(this.story)
            // 保存版本
            this.handleSave(3)
            this.$refs.headeNvWriterRef.syncUser()
          }).catch(error => {
            // 处理错误
            console.error(error);
            let currentOutlineFull = this.storyOutlineFulls.filter(i=>{return i.id==this.story.storyOutlineFullId})[0];
            if(currentOutlineFull){
              this.storyOutlineFull = currentOutlineFull.content
            }
            this.isLoading = false;
            this.isSkeleton = false;
          });
        }
    }
  },
  beforeDestroy() {
    console.log('gen3 before destroy')
  },
}
</script>

<style scoped lang="scss">
.custom-loading {
  /deep/ .el-loading-spinner .el-loading-text {
    color: #FFFFFF !important; /* 红色字体 */
  }
}
/deep/ .el-loading-spinner .el-loading-text {
    color: #FFFFFF !important; /* 红色字体 */
  }
</style>
