<template>
<div style="">
  <el-button v-if="storyOutline.genFlag" class="custom-button" :size="$root.buttonSize" type="default" round @click="handleOpen()" :loading="isLoading" :disabled="isLoading">更新故事图谱</el-button>
  <!-- <div @click="init">parent</div> -->
</div>
</template>
<script>
import { mapState, mapMutations} from 'vuex'
import sse from '@/utils/llmstream'
export default {
  name: 'GraphOpen',
  props: {
    storyOutline: Object,
    fromChannel: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  data() {
    return {
      isLoading: false,
    }
  },
  beforeDestroy() {

  },
  mounted() {

  },
  computed: {
  	...mapState(['showGraphPanelFlag', 'story']),
  },
  methods: {
    ...mapMutations(['setShowGraphPanelFlag']),
    init(){
      console.log(this.$parent.$parent);
    },
    llmStart() {
      this.isLoading = true
      sse("1792808128543162370", {articleId: this.story.id, storyOutlineId: this.storyOutline.id}, this.handleMsg).then((result)=>{
        console.log('result', result);
        this.isLoading = false
      })
    },
    handleMsg(msg){
      // console.log('msg', msg);

    },
    handleOpen(){
      console.log('parent', this.$parent, this.$parent.$parent);
      this.llmStart()
    }
  }
}
</script>

<style type="text/css">

</style>
<style scoped lang="scss">

</style>
