<template>
    <!-- 大纲生文 弹窗 -->
    <el-dialog
      :width="drawerWidth"
      :top="dialogTop"
      :append-to-body="true" class="custom-dialog new-dp-question-dialog"
      :withHeader="false"
      @close="close"
      :visible.sync="showGenbyoutlineFlag">
      <div style="font-weight: 600;margin-top: 1.5rem;
        font-size: 20px;
        color: rgba(0,0,0,0.9);">你想要以此生成什么新故事？</div>
      <el-row :gutter="10">
        <el-col :span="24" class="my-1">
          <span class="field-required-pre">*</span><span class="field-title">故事类型</span>
        </el-col>
        <el-col :span="24" v-if="storyTiaojian.length>0">
          <el-cascader class="custom-select-v1""
            v-model="articleTypeIdOptions"
            placeholder="类型"
            :options="storyTiaojian.filter(item1=>item1.code==1)[0].children.filter(item2=>item2.code==7)[0].children"
            @change="$handleChangeArticleTypeId($event, story)"
            :props="{
              expandTrigger: 'hover'
            }"
            :show-all-levels="false"
            filterable></el-cascader>
        </el-col>
      </el-row>

      <el-row :gutter="10">
        <!-- wenfeng -->
        <el-col :span="24" class="my-1">
          <span class="field-required-pre">*</span><span class="field-title">故事文风</span>
        </el-col>
        <el-col :span="24" v-if="storyTiaojian.length>0">
          <el-cascader class="custom-select-v1"
              v-model="articleStyleIdOptions"
              placeholder="文风"
              :options="storyTiaojian.filter(item1=>item1.code==5)[0].children"
              :props="{ expandTrigger: 'hover' }"
              @change="$handleChangeArticleStyleId($event, story)"
              :show-all-levels="false"
              filterable></el-cascader>
        </el-col>
      </el-row>

      <el-row :gutter="10" class="my-1">
        <el-col :span="24" class="my-1">
          <span class="field-required-pre">*</span><span class="field-title">写作视角</span>
        </el-col>
        <el-col :span="24" v-if="enums.WritingPerspectiveEnum">
          <el-radio-group v-model="story.writingPerspective">
            <el-radio :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.WritingPerspectiveEnum">{{item.desc}}</el-radio>
          </el-radio-group>
        </el-col>

        <el-col :span="24" class="my-1" style="display: flex;align-items: center;">
          <span class="field-required-pre">*</span><span class="field-title" style="margin-right: .25rem;">参考章节字数</span>
          <el-tooltip placement="top" style="display: inline-block;">
            <div slot="content">
              <div style="">
                由于AI算法的原因，目前无法精准控制生成的字数，生成的内容只能保持在一个大致的范围内。
              </div>
            </div>
            <div class="">
              <img style="height: 16px; display: inline-block;margin-top: 3px;" src="../assets/tishi-icon.png"/>
            </div>
          </el-tooltip>
        </el-col>
        <el-col :span="24" v-if="enums.ArticleOutlineLengthEnum">
          <el-radio-group v-model="story.storyOutlineLength">
            <el-radio :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.ArticleOutlineLengthEnum">{{item.brief}}</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row :gutter="10" type="flex" justify="center" style="margin-top: 2rem; margin-bottom: 1rem;">
        <el-button class="" type="primary" @click="createOrUpdateStoryByOutline">开 始</el-button>
      </el-row>
    </el-dialog>

</template>
<script>
import * as ArticleApi from '@/api/article'
import { mapState, mapMutations} from 'vuex'
import { nextTick } from 'vue'
export default {
  name: 'Gen4Outline',
  props: {
    enums: {
      type: Object,
      default: {}
    },
    channel: {
      type: String,
      default: ''
    }
  },
  components: {

  },
  data() {
    return {
      activeItem: {id: '0'},
      isSkeleton: false,
      ageTags: [],
      worldViewTags: [],
      placeTags: [],
      articleTypeIdOptions: [],
      articleStyleIdOptions: [],
      storyAnswers: [],
      storyContentRequired: ''
    }
  },
  mounted() {
    console.log('mounted');
  },
  computed: {
    ...mapState(['user', 'story', 'showGenbyoutlineFlag', 'storyTiaojian']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '85%' : '40%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "5%" : '10vh';
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowGenbyoutlineFlag']),
    close(){
      this.$nextTick(()=>{
        this.setShowGenbyoutlineFlag(false);
      })
    },
    createOrUpdateStoryByOutline() {
      if (!this.story.articleTypeId || !this.story.articleTypeId.trim() || Number(this.story.articleTypeId) == 0) {
          this.$message.error('请选择故事类型');
          return false;
      }
      if (!this.story.articleStyleId || !this.story.articleStyleId.trim() || Number(this.story.articleStyleId) == 0) {
          this.$message.error('请选择故事文风');
          return false;
      }
      if (!this.story.writingPerspective || !this.story.writingPerspective.trim()) {
          this.$message.error('请选择写作视角');
          return false;
      }
      if (!this.story.storyOutlineLength || !this.story.storyOutlineLength.trim()) {
          this.$message.error('请选择参考章节字数');
          return false;
      }
      // return false;
      if(this.channel == 'workspace_index'){
        ArticleApi.create(this.story).then(res => {
            if(res.data.code == 0){
              let data = res.data.data
              this.setStory(data)
              this.close()
              setTimeout(()=>{
                this.$router.push('/workspace/genbyoutline');
              }, 300)

            }else{
              this.$message.error(res.data.msg);
            }
        })
      }else if(this.channel == 'genbyoutline_regenelement'){
        this.setStory(this.story)
        ArticleApi.update(this.story).then(res => {

        })
      }
    }
  },
  beforeDestroy() {

  },
}
</script>

<style scoped lang="scss">

/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__headerbtn {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
}
/deep/ .el-dialog__body > div:first-of-type {
  padding: 0 0 .5rem 0 !important;
}
.custom-select-v1 {
  /deep/ .el-input__inner {
    border-radius: 20px;
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    background: #FFFFFF;
    border: 1px solid #EBEEF5 !important;
  }
  /deep/ .el-input__suffix {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  /deep/ .el-input--suffix {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}
/* 全局滚动条轨道的padding */
.question-box::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 1rem 0;
}
/* 滚动条的样式 */
.question-box::-webkit-scrollbar {
  height: 10px;
  margin: 1rem 0;
  background-color: transparent;
}
.question-box::-webkit-scrollbar-thumb {
  background-color: rgba(54,110,244,0.1);
}
.question-box {
  &-tip {
    font-weight: 600;
    font-size: 20px;
    color: rgba(0,0,0,0.9);
  }
  &-title {
    margin: 2rem 0;
    line-height: 28px;
    &-question {
      color: #366EF4;
      background: #F2F3FF;
      border-radius: 6px 6px 6px 6px;
      padding: .25rem .5rem;
      margin-right: .25rem;
    }
    font-weight: 600;
    font-size: 16px;
    color: rgba(0,0,0,0.9);
  }
  &-tag {
    margin-bottom: 1rem;
    &-title {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0,0,0,0.6);
      margin-bottom: .5rem;
    }
    &-item{
      display: inline-block;
      background: #F2F3FF;
      border-radius: 12px;
      font-weight: 400;
      font-size: 12px;
      color: #242424;
      padding: .25rem 1rem;
      white-space: nowrap;
      margin-right: 1rem;
      margin-bottom: .5rem;
      cursor: pointer;
      &-active {
        color: #366EF4;
      }
    }
  }
  &-required {
    font-weight: 400;
    font-size: 14px;
    &-title{
      color: rgba(0,0,0,0.6);
      margin-bottom: 1rem;
    }
    &-input{
      color: rgba(0,0,0,0.4);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #DCDCDC;
    }
  }
}
.answer-box {
  background: #F2F3FF;
  border-radius: 12px 12px 12px 12px;
  padding: 1rem;
  &-title{
    font-weight: 600;
    font-size: 16px;
    color: #366EF4;
  }
  &-content > :nth-child(2) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &-content{
    margin-top: 1rem;
    &-item:hover{
      border: 1px solid #366EF4;
    }
    &-item{
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #FFFFFF;
      padding: .5rem 1rem;
      &-left {
        flex: 1;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        line-height: 22px;
        text-align: justify;
        padding-right: 1rem;
      }
      &-right {
        display: flex;
        align-items: center;
        margin-left: 1rem;
      }
    }
  }
  &-tip{
    font-weight: 400;
    font-size: 12px;
    color: #366EF4;
    margin-top: .5rem;
  }
}
.img-tail {
  width: 18px;
  height: 18px;
  margin-left: .5rem;
}
.custom-dialog {
  /deep/ .el-dialog__headerbtn {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}

</style>
